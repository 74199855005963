@font-face {
  font-family: 'afaqy-icons';
  src: url('./afaqy-icons.eot?a0d7a67e183be62f5620ecd8335489d7?#iefix')
      format('embedded-opentype'),
    url('./afaqy-icons.woff2?a0d7a67e183be62f5620ecd8335489d7') format('woff2'),
    url('./afaqy-icons.woff?a0d7a67e183be62f5620ecd8335489d7') format('woff'),
    url('./afaqy-icons.ttf?a0d7a67e183be62f5620ecd8335489d7') format('truetype'),
    url('./afaqy-icons.svg?a0d7a67e183be62f5620ecd8335489d7#afaqy-icons')
      format('svg');
}

i {
  line-height: 1;
}

i[class^='afaqy-icon-']:before,
i[class*=' afaqy-icon-']:before {
  font-family: afaqy-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.afaqy-icon-building-landmark:before {
  content: '\f101';
}
.afaqy-icon-car-key:before {
  content: '\f102';
}
.afaqy-icon-car:before {
  content: '\f103';
}
.afaqy-icon-caret-bottom:before {
  content: '\f104';
}
.afaqy-icon-caret-circle-bottom:before {
  content: '\f105';
}
.afaqy-icon-caret-circle-left:before {
  content: '\f106';
}
.afaqy-icon-caret-circle-right:before {
  content: '\f107';
}
.afaqy-icon-caret-circle-top:before {
  content: '\f108';
}
.afaqy-icon-caret-left:before {
  content: '\f109';
}
.afaqy-icon-caret-right:before {
  content: '\f10a';
}
.afaqy-icon-caret-top:before {
  content: '\f10b';
}
.afaqy-icon-circle-top:before {
  content: '\f10c';
}
.afaqy-icon-clock:before {
  content: '\f10d';
}
.afaqy-icon-close-afaqy-icon-white:before {
  content: '\f10e';
}
.afaqy-icon-close-icon:before {
  content: '\f10f';
}
.afaqy-icon-company-settings:before {
  content: '\f110';
}
.afaqy-icon-compass:before {
  content: '\f111';
}
.afaqy-icon-customers:before {
  content: '\f112';
}
.afaqy-icon-drivers:before {
  content: '\f113';
}
.afaqy-icon-events:before {
  content: '\f114';
}
.afaqy-icon-fullscreen:before {
  content: '\f115';
}
.afaqy-icon-geofences:before {
  content: '\f116';
}
.afaqy-icon-help:before {
  content: '\f117';
}
.afaqy-icon-info:before {
  content: '\f118';
}
.afaqy-icon-language:before {
  content: '\f119';
}
.afaqy-icon-location:before {
  content: '\f11a';
}
.afaqy-icon-logo-circular:before {
  content: '\f11b';
}
.afaqy-icon-logo-afaqy-icon-square-border-radius:before {
  content: '\f11c';
}
.afaqy-icon-logo-afaqy-icon-square:before {
  content: '\f11d';
}
.afaqy-icon-logout:before {
  content: '\f11e';
}
.afaqy-icon-menu-toggler:before {
  content: '\f11f';
}
.afaqy-icon-monitoring:before {
  content: '\f120';
}
.afaqy-icon-notifications:before {
  content: '\f121';
}
.afaqy-icon-reports:before {
  content: '\f122';
}
.afaqy-icon-resources:before {
  content: '\f123';
}
.afaqy-icon-routes:before {
  content: '\f124';
}
.afaqy-icon-satalite-signal:before {
  content: '\f125';
}
.afaqy-icon-sea-level:before {
  content: '\f126';
}
.afaqy-icon-search:before {
  content: '\f127';
}
.afaqy-icon-settings:before {
  content: '\f128';
}
.afaqy-icon-speed:before {
  content: '\f129';
}
.afaqy-icon-status:before {
  content: '\f12a';
}
.afaqy-icon-support:before {
  content: '\f12b';
}
.afaqy-icon-theme-colors:before {
  content: '\f12c';
}
.afaqy-icon-time-zone:before {
  content: '\f12d';
}
.afaqy-icon-tracking:before {
  content: '\f12e';
}
.afaqy-icon-trailers:before {
  content: '\f12f';
}
.afaqy-icon-units:before {
  content: '\f130';
}
.afaqy-icon-user-preferences:before {
  content: '\f131';
}
.afaqy-icon-users:before {
  content: '\f132';
}
.afaqy-icon-zone-geolocation:before {
  content: '\f133';
}
