/* sm up start */
@include media-breakpoint-up(sm) {
}

/* sm up start */
/* sm down start */
@include media-breakpoint-down(sm) {
  .responsive {
    .navbar {
      &.navbar-primary {
        height: 39px !important;

        .brand-shape {
          height: 44px !important;
          margin-right: 32px !important;

          .brand-container {
            .navbar-brand {
              img {
                max-width: 200px !important;
                max-height: 28px !important;
              }
            }
          }

          &::before {
            border-width: 44px 22px 0 0 !important;
          }

          &.brand-shape-inverted {
            margin-right: 0px !important;
            box-shadow: -4px 0px 4px 3px rgba(0, 0, 0, 0.5) !important;
            padding-left: 0 !important;
            transition: all 300ms ease-in-out;
            transform: none;
            justify-content: right;
            min-width: 165px !important;

            &.MenuOpen {
              transform: translateX(120%);
            }

            &.NotificationsOpen,
            &.UserSidebarOpen {
              min-width: 275px !important;
            }

            &::before {
              border-width: 0 22px 44px 0 !important;
            }

            button {
              height: 44px !important;
              width: 44px !important;
              margin: 0 !important;
              padding: 7px !important;
              background: transparent !important;
              display: flex !important;
              justify-content: center !important;
              transition: all 0.2s ease-in-out;

              i {
                padding: 0 !important;
              }

              &.active {
                background: var(--them-color) !important;
                color: #fff !important;

                i {
                  color: #fff !important;
                }
              }

              &:focus,
              &.focus {
                outline: none;
              }
            }

            .LoginAs {
              height: 44px !important;
              width: auto !important;
              align-items: center !important;
            }
          }
        }

        .height-30 {
          height: 39px !important;
        }

        .current-tab-style {
          font-size: 16px !important;
          max-width: auto;

          .icon-18px {
            font-size: 21px;
            display: flex;
          }
        }

        .current-tab::before {
          left: -31px !important;
          width: 31px !important;
          height: 39px !important;
          border-width: 0 0 39px 22px !important;
        }

        .current-tab::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: -22px;
          width: 22px;
          height: 39px;
          border-style: solid;
          border-width: 39px 22px 0 0;
          border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
        }
      }

      #notifications-sidebar {
        // top: 44px !important;

        .buttons-container {
          .btn {
            padding: 5px 7px !important;
            font-size: 16px !important;
          }
        }
      }
    }

    .custom-backdrop {
      display: block !important;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.85);
      opacity: 1;
      z-index: 1047;
    }

    #sidebar {
      top: 39px !important;

      .modal-dialog {
        width: 240px !important;
        margin-top: 5px;

        .modal-body {
          .list-group {
            li {
              a {
                min-height: 44px;
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: normal;

                &.dropdown-toggle::after {
                  display: flex;
                  position: relative;
                  margin-left: auto;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }

      .fade {
        .modal-dialog {
          left: 240px !important;
          transition: opacity 0.3s linear, left 0.3s ease-out;
        }
      }

      // &.MenuOpen {
      //     .custom-backdrop {
      //         display: block !important;
      //         opacity: 1;
      //     }
      // }
    }

    afaqy-menu .modal-body {
      padding-bottom: 44px !important;
    }

    .brand-container {
      transition: all 300ms ease-in-out;
      transform: translateX(-183px) !important;

      .navbar-toggler {
        span {
          transition: all 0.3s ease-in-out 0.3s !important;
        }
      }

      &.MenuOpen {
        transform: none !important;
      }

      &.NotificationsOpen,
      &.UserSidebarOpen {
        transform: translateX(-400px) !important;
      }
    }

    .AboutAfaqy,
    .toggleFullScreen,
    .searchDivBox {
      display: none !important;
    }

    .modal {
      &.right {
        .modal-dialog {
          top: 44px !important;
          width: 275px !important;
        }
      }
    }

    .navbar .notifications .badge {
      top: 1px !important;
      right: 0px !important;
    }

    .modal {
      .nav-tabs {
        /* debug mode on */
        height: 44px;
        // display: inline-flex;
        list-style-type: none;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        .nav-item {
          .nav-link {
            padding: 10px 20px !important;
            font-size: 16px !important;
            display: inline-flex !important;
            justify-content: center !important;
            align-items: center !important;
          }
        }
      }
    }

    #footer {
      height: 44px !important;
      display: flex !important;
      align-items: center !important;

      .btn {
        height: 35px;
      }

      .DataMapSwitch {
        padding: 5px 10px;
      }
    }

    // .options-list {
    //     top: 0 !important;
    //     left: 0 !important;

    // }

    .notifications-heading {
      padding: 5px 0;
    }

    .notifications_actions {
      a {
        font-size: 18px;
        padding-right: 10px;
      }
    }

    .notifications-item {
      padding: 10px 15px !important;
      width: 100%;
    }

    #user-settings-sidebar {
      // top: 44px !important;
      // .modal-body {
      //     padding: 0 15px !important;
      // }
    }

    .optionsListContainer {
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      max-width: none !important;
      max-height: none !important;
      height: 100% !important;

      .header {
        height: 44px !important;
        align-items: center !important;
        padding: 15px !important;
      }

      .overflow-y {
        height: calc(100% - 44px) !important;
        overflow-y: auto !important;
      }

      .table {
        th,
        td {
          padding: 0.6rem !important;
        }

        th {
          color: var(--them-color);
        }

        thead {
          display: table-header-group !important;
          background: #f7f7f7;
          font-weight: bold;
        }
      }
    }

    .modal-dialog {
      &.modal-lg,
      &.modal-responsive {
        top: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        height: 100% !important;
        max-width: none !important;
        padding: 0 !important;
        transform: none !important;

        .modal-content {
          height: 100% !important;
          width: 100% !important;

          .modal-header {
            height: 48px !important;
            display: flex;
            align-items: center;
          }

          .modal-body {
            // height: 100% !important;
            height: calc(100% - 48px) !important;

            .modalbody {
              height: 100% !important;
            }

            .tab-content {
              > .active {
                height: calc(
                  100vh - var(--tabs-height) - var(--modal-header) - 90px
                );
              }
            }
          }
        }
      }
    }

    .switch {
      width: 32px;
      height: 16px;
      top: auto;
      margin-bottom: 0;

      input {
        &:checked + .slider:before {
          transform: translateX(16px);
        }
      }

      .slider {
        &:before {
          height: calc(100% - 4px);
          width: calc(50% - 4px);
          top: 2px;
          left: 2px;
        }
      }
    }

    .wj-cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      /* expermental to be used along with wj re init script on browser resize or orientation */
      // min-width: 44px;
      // min-height: 44px;
    }

    #userPermissionsGroups .parentPermissions {
      .nav.nav-stacked.flex-column.nav-tabs .nav-link {
        width: 100% !important;
        text-align: left !important;
        justify-content: flex-start !important;
        display: flex !important;
      }

      .nav-tabs {
        border: 1px solid #ccc !important;
      }
    }

    .assignUserOptionsMain {
      padding: 15px 0 !important;
    }

    unit-sensor-form .afaqy-modal-content {
      height: 100% !important;
    }

    .LoginAs {
      padding: 0 7px;

      .btn {
        padding: 0 !important;
        width: auto !important;
        display: flex !important;
        align-items: center;
      }
    }

    .listing-content {
      .dataHeading {
        margin: 0 !important;
        width: 50% !important;
      }

      afaqy-actions {
        width: 50% !important;
        margin: 0 !important;
      }

      .page-heading-buttons {
        height: 44px;
        list-style-type: none;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        .btn {
          padding: 0 15px 0 10px !important;
          height: 44px !important;
        }
      }
    }

    .listOptions {
      position: relative !important;
      margin-top: 0 !important;
      top: initial !important;
      left: initial !important;

      label {
        &.title {
          width: 100% !important;
        }
      }
    }

    .chatBoxClass {
      .tab-content {
        margin-right: 15px !important;
      }

      wj-flex-grid {
        height: 94px !important;
      }

      .modal-dialog.modal-lg .modal-content .modal-body .tab-content > .active,
      .modal-dialog.modal-responsive
        .modal-content
        .modal-body
        .tab-content
        > .active {
        height: auto !important;
      }

      .modalbody {
        overflow-y: auto !important;
        max-height: 100% !important;
      }

      .content {
        height: auto !important;
      }

      .chat-container {
        height: auto !important;
      }

      .messagesContainer {
        height: 20vh;
        overflow-y: auto;
      }

      .intervals {
        padding: 10px 0;
      }
    }

    .loginContainer {
      .container {
        padding: 0;

        .tile {
          background: rgba(255, 255, 255, 0.8);
        }
      }
    }

    /* end responsive class */
  }

  // .additionLi {
  //     display: flex !important;
  // }
  // body.page-sidebar-left-show #header {
  //     left: 75px;
  // }
  // body.page-footer-fixed.page-sidebar-left-show #page-content .footer-content {
  //     left: 75px;
  //     right: -220px;
  // }
  // body.page-sidebar-left-show #page-content {
  //     margin-left: 75px !important;
  // }
  // body.page-header-fixed.page-sidebar-left-show .navbar-toolbar {
  //     left: 75px;
  // }
  // #left-content {
  //     width: 100%;
  // }
  // #map-content-content {
  //     width: 100%;
  // }
  // body.page-footer-fixed.page-sidebar-minimize #page-content .footer-content,
  // body.page-footer-fixed.page-sidebar-minimize-auto #page-content .footer-content {
  //     left: 0 !important;
  // }
}

/* sm down start */
