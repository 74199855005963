// @import "font-awesome/css/font-awesome.css";
@import '@mdi/font/scss/materialdesignicons.scss';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import '@fortawesome/fontawesome-free/css/v4-shims.css';
/* migrating from 4 > 5 : required till totally upgrading all html to ver 5 */
@import './rtl';
@import 'fonts/afaqy-icons.css';
@import './css/icons.css';
@import './variables';
@import '../../../../node_modules/hover.css/scss/hover';
@import '../../../../node_modules/bootstrap/scss/bootstrap';
/* custom directional bootstrap */
//@import "../assets/scss/bootstrap/bootstrap-directional.scss";

@import '../../../../node_modules/angular2-draggable/css/resizable.min.css';
@import '../../../../node_modules/animate.css/animate.min.css';
@import './responsive';
//@import "../assets/css/wijmo.css";
@import '@grapecity/wijmo.styles/wijmo';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');
@import url('https://fonts.googleapis.com/earlyaccess/droidarabickufi.css');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600|Oswald:300,400');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800&display=swap');
/* dashboard font as per design */
@import '../assets/custom-font-icon/css/afaqy-custom.css';
/* custom icons */
@import '@ng-select/ng-select/themes/default.theme.css';
/* material */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './scss/utilities.scss';
@import './scss/matrial-override.scss';

@import 'svg/avl-icons';
@import '../assets/svg/icon-sets/avl-icons-v1/icons-color-theme';
@import '../../../../node_modules/tippy.js/dist/tippy.css';
@import '../../../../node_modules/tippy.js/themes/light.css';
// @import "../../../../node_modules/slick-carousel/slick/slick.css";
// @import "../../../../node_modules/slick-carousel/slick/slick-theme.css";

/* override style */
@import './scss/override-style.scss';
@import './scss/report-form-style.scss';

//@import '~primeicons/primeicons.css';
@import '../../../../node_modules/primeng/resources/themes/nova-light/theme.css';
//@import '~primeng/resources/primeng.min.css';

@import url('../../../../node_modules/primeicons/primeicons.css');
//@import url("../../../../node_modules/primeng/resources/themes/saga-orange/theme.css");
@import url('../../../../node_modules/primeng/resources/primeng.min.css');

// @import './scrollbar';

/* Report Form style */
@import './scss/report-form-style.scss';

@function derive-rgb-values-from-hex($hex) {
  @return red($hex), green($hex), blue($hex);
}
@import './breakpoints';

:root {
  --them-color: #0c6667;
  --them-color-abstract-highlight: 12, 102, 103;
  --error-color: #dc3545;
  --base-height: 0px;
  --modal-height: 250px;
  --modal-content-height: 200px;
  --left-content-form-height: 200px;
  --tabs-height: 32px;
  --direction-primary: left;
  --direction-secondary: right;
  --direction-absolute-left: 0;
  --direction-absolute-right: auto;
  --direction-multiplier: 1;
  --transformOrigin: 0 0;

  /**
        styles only ltr
    **/

  &[dir='ltr'] {
    .loginContainer {
      .loading-bar-fixed {
        > div#loading-bar-spinner {
          position: fixed;
          left: auto !important;
          right: 115px !important;
          top: 20px !important;
        }
      }
    }

    .loadingbar-container {
      position: absolute;
      height: 6px;
      top: 30px;
      left: 235px;
      right: 240px;
      width: calc(100% - 240px - 235px);
      z-index: 2;

      .loading-bar-fixed {
        > div#loading-bar {
          position: relative !important;

          .bar {
            position: relative !important;
          }
        }
      }

      .loading-bar-fixed {
        > div#loading-bar-spinner {
          position: absolute !important;
          left: auto !important;
          right: 45px !important;
          top: -23px !important;
          color: #fff !important;
        }
      }
    }

    .brand-shape-inverted {
      .nav-right-buttons {
        li {
          border-right: 1px solid $gray-250;

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    madinati-form {
      .form-control-sm {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }
}
.table-responsive {
  height: 100% !important;
  overflow: hidden;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow-y: hidden;
  padding: 0 !important;
  height: 100%;
  text-align: left;
  font-size: 0.9rem !important;
}

* {
  &:focus {
    outline-style: none !important;
  }

  .btn-link:hover {
    text-decoration: none;
    cursor: pointer;
  }

  //transition: background 0.3s ease, color 0.05s ease; //#page-container {
  .lng-ar-ltr label {
    direction: rtl;
  }

  .lng-ar,
  .lng-ar-ltr {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .form-control,
    button,
    .list-group-item a,
    .current-tab-style span,
    .wj-header,
    .page-heading-buttons button,
    .switchLabel span,
    li.nav-item span,
    .modal-header h5,
    .afaqy-tooltip-body {
      font-family: 'Cairo', sans-serif;
    }

    .wj-calendar-month tr.wj-header td {
      padding: 5px;
    }

    select {
      padding-top: 1px;
    }
  }

  .dragged-section {
    position: absolute;
    right: 0;
    top: 35px;
    width: 250px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 5;

    .list-unstyled {
      height: 200px;
      overflow-y: auto;

      li {
        border-bottom: 1px solid #e3e3e3;
      }
    }

    .loginAsIcon,
    .loginAsName {
      border: none !important;
      cursor: pointer;
    }
  }

  chat-list .dragged-section {
    width: 200px !important;
    right: 32px !important;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    top: 30px !important;

    .list-unstyled {
      height: calc(var(--modal-content-height) - 200px) !important;
    }
  }

  .active.tab-pane {
    position: relative;
  }

  ::ng-deep .modalbody,
  ::ng-deep .report-form {
    form {
      padding: 0;
    }

    // input:not([type="checkbox"]):not([type="radio"]), select {
    //   // height: 35px !important;
    //   // line-height: 2 !important;
    //   background: linear-gradient(to bottom, white 0%, #f9f9f9 60%) !important;
    // }
    // .form-control-label {
    //   color: var(--them-color);
    //   margin-bottom: 0;
    //   font-weight: bold;
    // }
    .wj-btn-default {
      background: linear-gradient(to bottom, white 0%, #f9f9f9 60%) !important;
    }

    label.btn {
      span {
        font-family: 'Oswald', sans-serif;
      }
    }
  }

  #belowMapArea .options-list {
    overflow-y: auto;
  }

  .wj-listbox {
    overflow-y: auto !important;
    // max-height: var(--left-content-form-height) !important;
    /* fix for filter grid */
    max-height: calc(var(--left-content-form-height) - 80px) !important;
  }

  .chatBoxClass {
    .item-r {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  //.customLink {
  //  color: var(--them-color) !important;
  //  text-decoration: underline !important;
  //  position: absolute;
  //  right: 15px;
  //  top: 2.5px;
  //  font-size: 0.6rem;
  //}
  .separator {
    height: 0;
    width: 100%;
  }

  .btn-customHeight {
    height: 29px;
    line-height: 0;
  }

  // .servicesCustomPadding {
  //   .thirdLayer {
  //     .modal-content {
  //       top: 100px;
  //     }
  //   }
  // }
  // .input-group-text {
  //   color: #ffffff;
  //   background: var(--them-color);
  //   border: 1px solid rgba(0, 0, 0, 0.1);
  // }
  .popOverInputs {
    display: flex;
    flex-direction: column;
    background: white;
    z-index: 2;
    overflow: hidden;
    width: 370px;
    flex-wrap: wrap;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 35px;
    right: 35px;
  }

  // Resetting styles for modals to be in modals component
  // .adjustHeightModal {
  //   .modal-body {
  //     overflow-y: hidden;
  //   }
  //   .tab-content {
  //     //height: calc(var(--page-content-height) - 230px) !important;
  //     /* calculate from base 80vh of modal height and remove modal header footer and site footer from that */
  //     height: calc(
  //       80vh + 25px /*footer height*/ - 145px /*modal header & footer */
  //     ) !important;
  //   }
  // }
  .unitProfile {
    min-height: calc(var(--modal-content-height) - 80px);
  }

  .thirdLayer {
    // .modal-content {
    //   top: 20px;
    // }
    .afaqy-modal .modal-body {
      //max-height: var(--modal-height);
    }

    .page-content-title {
      background: white !important;
      color: var(--them-color);
      font-size: 15px;
      font-weight: 400;
    }
  }

  .spinnerInsideButton {
    .spinner {
      margin: 0;
    }
  }

  // .show {
  //   display: block !important;
  // }
  .hide {
    display: none !important;
  }

  .breakText {
    white-space: pre-line;
    word-break: break-all;
  }

  .white-space-nowrap {
    white-space: nowrap !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .page-headers {
    flex-wrap: wrap;
  }

  .auditlogViewTables {
    max-height: calc(var(--modal-content-height) - 200px);

    h5 {
      font-weight: 700;
      color: var(--them-color);
      text-align: left !important;
    }

    table {
      margin-bottom: 1.3rem;
      border-color: #f6f6f6 !important;
    }

    .oldValue {
      text-decoration: line-through;
      color: #999;
    }

    .newValue {
      background: #0080001c;
    }

    h6 {
      margin-bottom: 0.5rem;
    }
  }

  .main-lockscreen-contain {
    img {
      width: 100%;
    }
  }

  #user-settings-sidebar-key {
    &:after {
      display: none;
    }
  }

  #page-listing-body > wj-flex-grid img.center {
    max-height: 20px;
  }

  .open {
    .dropdown-menu {
      display: block;
    }
  }

  .btn-primary {
    color: white;

    &:hover {
      color: white;
    }
  }

  .follow-container {
    .unit-tooltip-container {
      min-width: 100%;
    }

    .btn-light {
      color: white;
      background: transparent !important;
      border-color: transparent !important;

      &:active {
        color: white !important;

        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }

  .page-item.active .page-link {
    background: var(--them-color);
    border-color: var(--them-color);
  }

  .nav.nav-stacked.flex-column.nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
  }

  // .table-responsive {
  //     background: #f7f7f7;
  // }

  #cmsMainContent {
    .table-responsive {
      overflow-x: hidden !important;

      .listing-content {
        background: #f7f7f7;
        min-height: 82.5vh !important;
      }
    }
  }

  .cmsmodal {
    .modal {
      width: var(--cms-main-content-width);
      height: calc(var(--page-content-height) - 90px) !important;
      position: absolute !important;
    }

    .secondLayer {
      .modal {
        width: auto;
        height: calc(var(--page-content-height) - 265px) !important;
      }
    }
  }

  // .filter {
  //   .btn.link.pointer {
  //     padding-top: 0;
  //   }
  // }
  .libraryIcons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    button {
      img {
        width: 26px;
        height: 26px;
      }
    }
  }

  .btn-social-icon {
    background: white;
  }

  // .bootstrap {
  //   tag {
  //     background: var(--them-color) !important;
  //   }
  // }
  .input-group > .form-control:focus {
    z-index: 0;
  }

  .wj-cell.wj-header {
    text-align: center !important;
    color: #fff;
    font-weight: lighter;
  }

  .modalbody {
    position: relative;
  }

  .modalfooter {
    button {
      margin: 0 5px;
    }
  }

  /* added note by samy: please don't write rules like this because it override any classes for tables we use in pages */
  // .table th,
  // .table td {
  //   padding: 0.2rem;
  //   vertical-align: top;
  // }
  .form-inline {
    select {
      border-radius: 20px;
      margin: 7px 10px;
      border: none;
      background: #ececec;
      color: var(--them-color);
    }

    input {
      border: none;
      background: #ececec;
      border-radius: 20px;
      margin: 7px;
      vertical-align: middle;
      padding: 5px 10px;
      width: 22%;
      color: var(--them-color);
    }
  }

  table.borderless {
    table.table-responsive-sm {
      background: transparent;
    }
  }

  .icon-zone-line,
  .icon-zone-circle,
  .icon-zone-polygone {
    color: var(--them-color);
  }

  i {
    &::before {
      vertical-align: middle !important;
    }
  }

  .btn:focus,
  .btn-primary:focus,
  .btn-outline-primary.focus,
  .btn-outline-primary:focus,
  .btn-light:focus {
    outline: none;
    box-shadow: none;
  }

  .has-danger {
    .form-control {
      border-color: $error-color;
    }
  }

  .form-control:focus {
    border-color: var(--them-color);
  }

  // tabset {
  //   .nav-item.active a {
  //     color: white !important;
  //   }
  // }
  #page-listing-body {
    label.icon-eye {
      margin: 0 !important;
      line-height: 12px !important;
    }
  }

  #header {
    z-index: 1 !important;
  }

  // #userPermissionsGroups {
  //   tabset {
  //     .nav-tabs {
  //       //background: white !important;
  //       border: 1px solid var(--them-color);
  //       border-radius: 10px;
  //       .nav-link {
  //         border: none;
  //         border-radius: 0;
  //         &:hover {
  //           color: white;
  //           background: var(--them-color);
  //         }
  //       }
  //     }
  //   }
  // }
  split-gutter:hover {
    background-color: rgba(0, 0, 0, 0.15) !important;
  }

  .btn-sm {
    padding: 0px 5px;
  }

  .form-control:focus {
    box-shadow: 0 0 0 0.2rem transparent;
  }

  a {
    color: var(--them-color);

    &:hover {
      color: var(--them-color);
      text-decoration: none;
    }
  }

  .nav-link {
    padding: 0.2rem 0.5rem; // font-size: 12px;
    font-weight: bold;
    border: none;
    color: var(--them-color);

    &.active {
      background: var(--them-color);
      color: white;
    }
  }

  .ribbon-box {
    position: relative;
    border: 1px solid #ccc;
    padding: 55px 20px 20px;
    background: #ededed;
  }

  .ribbon-box.left-ribbon-box {
    margin-left: 20px;
  }

  .ribbon-box.left-ribbon-box.default-left-ribbon-box {
    padding: 45px 20px 20px 45px;
  }

  .ribbon-box.left-ribbon-box.default-bottom-left-ribbon-box {
    padding: 20px 20px 45px 45px;
  }

  .ribbon-box .ribbon-position {
    min-width: 100px;
    position: absolute;
    text-align: center;
    top: 10px;
    line-height: 34px;
    color: #fff;
  }

  .ribbon-box .ribbon-position:after,
  .ribbon-box .ribbon-position:before {
    position: absolute;
    content: '';
    height: 0;
    width: 0;
    border-style: solid;
    border-width: 0;
  }

  .ribbon-box .left-ribbon-position {
    left: -8px;
  }

  .ribbon-box .right-ribbon-position {
    right: -8px;
  }

  .ribbon-box .rounded-ribbon.left-ribbon-position {
    left: -22px;
  }

  .ribbon-box .rounded-ribbon.right-ribbon-position {
    right: -22px;
  }

  .ribbon-box .sheer-ribbon::before {
    left: -10px;
    border-width: 34px 10px 0 0;
  }

  .ribbon-box .sheer-ribbon::after {
    right: -10px;
    border-width: 34px 10px 0 0;
  }

  .ribbon-box .default-ribbon {
    min-width: 125px;
    line-height: 25px;
    background: linear-gradient(
      to bottom,
      rgba(121, 167, 10, 0.9) 0,
      #79a70a 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E679A70A', endColorstr='#FF79A70A', GradientType=0);
    box-shadow: #000 0 3px 10px -5px;
    top: 19px;
  }

  .ribbon-box .default-ribbon.left-ribbon-position {
    transform: rotate(-45deg);
    left: -32px;
  }

  .ribbon-box .default-ribbon.right-ribbon-position {
    transform: rotate(45deg);
    right: -32px;
  }

  .ribbon-box .default-ribbon::before {
    left: 0;
    top: 100%;
    border: 3px solid;
    border-color: #79a70a transparent transparent #79a70a;
  }

  .ribbon-box .default-ribbon::after {
    right: 0;
    top: 100%;
    border: 3px solid;
    border-color: #79a70a #79a70a transparent transparent;
  }

  .ribbon-box.default-ribbon-position {
    margin: 0 15px;
  }

  .ribbon-box .extra-arrow-ribbon {
    width: 100%;
    background-color: #8d96ed;
    top: 0;
    left: 0;
    box-shadow: #000 0 3px 10px -5px;
  }

  .ribbon-box .extra-arrow-ribbon::before {
    border-color: #8d96ed #8d96ed #8d96ed #fff;
    border-width: 17px;
    left: -34px;
    top: 5px;
  }

  .ribbon-box .extra-arrow-ribbon::after {
    border-color: #8d96ed #fff #8d96ed #8d96ed;
    border-width: 17px;
    right: -34px;
    top: 5px;
  }

  .ribbon-box .side-ribbon {
    top: 5px;
    right: 16px;
    transition: line-height 0.5s;
  }

  .ribbon-box .side-ribbon::after {
    border: 24px solid #f44336;
    top: -16px;
    border-bottom-color: transparent;
    right: 0;
  }

  .ribbon-box .side-ribbon::before {
    border-width: 0 0 10px 9px;
    border-color: transparent transparent #d2190b;
    top: -16px;
    right: 48px;
  }

  .ribbon-box .bottom-left-simple-ribbon,
  .ribbon-box .bottom-right-simple-ribbon,
  .ribbon-box .left-simple-ribbon,
  .ribbon-box .right-simple-ribbon {
    background: #f82772;
    box-shadow: #000 0 3px 10px -5px;
  }

  .ribbon-box .bottom-left-simple-ribbon.left-ribbon-position,
  .ribbon-box .bottom-right-simple-ribbon.left-ribbon-position,
  .ribbon-box .left-simple-ribbon.left-ribbon-position,
  .ribbon-box .right-simple-ribbon.left-ribbon-position {
    left: -13px;
  }

  .ribbon-box .bottom-left-simple-ribbon.right-ribbon-position,
  .ribbon-box .bottom-right-simple-ribbon.right-ribbon-position,
  .ribbon-box .left-simple-ribbon.right-ribbon-position,
  .ribbon-box .right-simple-ribbon.right-ribbon-position {
    right: -13px;
  }

  .ribbon-box .bottom-left-simple-ribbon.bottom-left-ribbon-position,
  .ribbon-box .bottom-right-simple-ribbon.bottom-left-ribbon-position,
  .ribbon-box .left-simple-ribbon.bottom-left-ribbon-position,
  .ribbon-box .right-simple-ribbon.bottom-left-ribbon-position {
    left: -13px;
    bottom: 10px;
    top: auto;
  }

  .ribbon-box .bottom-left-simple-ribbon.bottom-right-ribbon-position,
  .ribbon-box .bottom-right-simple-ribbon.bottom-right-ribbon-position,
  .ribbon-box .left-simple-ribbon.bottom-right-ribbon-position,
  .ribbon-box .right-simple-ribbon.bottom-right-ribbon-position {
    right: -13px;
    bottom: 10px;
    top: auto;
  }

  .ribbon-box .left-simple-ribbon::before {
    border-color: #b30644 #b30644 transparent transparent;
    border-width: 6px;
    bottom: -2px;
    left: 0;
    margin-bottom: -10px;
  }

  .ribbon-box .right-simple-ribbon::before {
    border-color: #b30644 transparent transparent #b30644;
    border-width: 6px;
    bottom: -2px;
    right: 0;
    margin-bottom: -10px;
  }

  .ribbon-box .bottom-left-simple-ribbon::before {
    border-color: transparent #b30644 #b30644 transparent;
    border-width: 6px;
    bottom: 34px;
    left: 0;
  }

  .ribbon-box .bottom-right-simple-ribbon::before {
    border-color: transparent transparent #b30644 #b30644;
    border-width: 6px;
    bottom: 34px;
    right: 0;
  }

  .ribbon-box .folded-ribbon {
    background: #c94700;
    left: 0;
    width: 100%;
    box-shadow: #000 0 3px 10px -5px;
  }

  .ribbon-box .folded-ribbon::after,
  .ribbon-box .folded-ribbon::before {
    content: '';
    display: block;
    width: 50px;
    height: 0;
    right: 0;
    bottom: 0;
    border-right: 33px solid transparent;
    position: absolute;
  }

  .ribbon-box .folded-ribbon::after {
    z-index: 20;
    border-bottom: 33px solid #e35000;
    transform: rotate(90deg);
    transform-origin: right bottom;
  }

  .ribbon-box .folded-ribbon::before {
    z-index: 10;
    border-bottom: 33px solid rgba(0, 0, 0, 0.3);
    transform: rotate(80deg);
    transform-origin: right bottom;
  }

  .ribbon-box .left-border-ribbon.left-ribbon-position,
  .ribbon-box .right-border-ribbon.left-ribbon-position {
    left: -19px;
  }

  .ribbon-box .border-ribbon {
    min-width: 200px;
    background: #5cb85c;
    position: absolute;
    text-align: center;
    top: 10px;
    padding: 8px;
    color: #fff;
    box-shadow: #000 0 3px 10px -5px;
  }

  .ribbon-box .border-ribbon.left-side {
    left: -13px;
  }

  .ribbon-box .border-ribbon.right-side {
    right: -13px;
  }

  .ribbon-box .border-ribbon.right-round-border {
    border-radius: 0 4px 4px 0;
  }

  .ribbon-box .border-ribbon.right-round-border::after {
    border-right: 1px dashed;
    left: 0;
    right: 5px;
  }

  .ribbon-box .border-ribbon.left-round-border {
    border-radius: 4px 0 0 4px;
  }

  .ribbon-box .border-ribbon.left-round-border::after {
    border-left: 1px dashed;
    left: 5px;
    right: 0;
  }

  .ribbon-box .border-ribbon::after {
    border: 1px dashed;
    border-left: none;
    border-right: none;
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 0;
    right: 0;
  }

  .ribbon-box .left-border-ribbon::before {
    border-color: #222 #222 transparent transparent;
    border-width: 6px;
    bottom: -38px;
    left: 19px;
  }

  .ribbon-box .right-border-ribbon::before {
    border-color: #222 transparent transparent #222;
    border-width: 6px;
    bottom: -38px;
    right: 8px;
  }

  .well.well-lg {
    .table td {
      vertical-align: baseline;
    }
  }

  .trashedCtrlBtns .btn {
    padding: 0;
    margin: 0 5px;
  }

  #afaqy-tooltip {
    .afaqy-tooltip-body {
      padding: 5px 10px;
      border-radius: 5px;
    }
  }

  .accessTab {
    .heightControl {
      padding: 0 !important;
      overflow: hidden;
    }
  }

  .switch {
    display: inline-block;
    width: 20px;
    height: 10px;
    top: 5px;
    position: relative;

    input {
      display: none;

      &:disabled + .slider {
        background: $gray-300;
      }

      &:checked + .slider {
        background-color: #64bd63;
      }

      &:checked {
        &:disabled + .slider {
          background: #08a50875;
        }
      }

      &:focus + .slider {
        box-shadow: 0 0 1px #64bd63;
      }

      &:checked + .slider:before {
        transform: translateX(10px);
      }
    }

    .slider.round {
      border-radius: 20px;

      &:before {
        border-radius: 50%;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $gray-600;

      &:before {
        position: absolute;
        content: '';
        height: calc(100% - 2px);
        width: calc(50% - 2px);
        top: 1px;
        background-color: $white;
        box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.32);
        transition: all 0.2s ease-in-out;
        left: 1px;
      }
    }

    .slider.round {
      border-radius: 20px;
    }

    .slider.round:before {
      border-radius: 50%;
    }

    &.switch-lg {
      width: 32px;
      height: 16px;
      top: auto;
      margin-bottom: 0;

      input {
        &:checked + .slider:before {
          transform: translateX(16px);
        }
      }

      .slider {
        &:before {
          height: calc(100% - 4px);
          width: calc(50% - 4px);
          top: 2px;
          left: 2px;
        }
      }
    }
  }

  tag-input {
    border: none !important;
  }

  .bootstrap.ng2-tag-input.ng2-tag-input--focused {
    border-bottom: 2px solid var(--them-color);
  }

  tag-input {
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;

    .ng2-tag-input {
      min-height: 90px !important;
    }

    .ng2-tags-container tag {
      padding: 0 10px;
      margin-top: 5px;
      background: #1c7cd5;
    }

    p.error-message {
      margin-top: 0;
    }
  }

  .ol-viewport {
    .ol-control {
      button.ol-zoomslider-thumb {
        &:hover {
          background: var(--them-color) !important;
        }
      }

      button {
        padding-bottom: 5px;
        box-shadow: 1px 1px 3px #cecece;

        &:focus,
        &:hover {
          color: var(--them-color);
          background-color: white !important;
        }
      }
    }
  }

  .ol-unselectable {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ol-zoomslider {
      &:hover {
        background-color: white;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }

  .classicmoal {
    .modalbody {
      width: auto;
      overflow-y: auto;
      overflow-x: hidden;

      .element-form {
        margin: 0;

        .heading-card {
          .element-form {
            padding: 0;
            display: flex;
            align-items: center;
          }
        }
      }

      .page-content-title {
        text-align: center;
        padding: 5px 0;
        margin: 5px 0;
      }

      .icon_selector {
        display: flex;
        justify-content: space-around;
      }
    }
  }

  .ol-control {
    background-color: transparent;
  }

  .ol-zoomslider {
    height: 5px;
    background: white;
    box-shadow: 1px 1px 3px #cecece;
  }

  // .modal-header,
  .right-side-bar .icon-right {
    background: var(--them-color) !important;
  }

  // .modal .modal-dialog .modal-content {
  // border: 1px solid var(--them-color);
  // }
  .x-mask-msg-inner {
    color: var(--them-color) !important;
  }

  .spinner4-small {
    background-color: var(--them-color) !important;
  }

  .contain-error a {
    color: var(--them-color);
  }

  #page-listing {
    position: relative;
    background: #f7f7f7;
    .totalItem {
      p {
        margin: 0px;
      }
    }
  }
  #page-no-items {
    p {
      position: absolute !important;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    afaqy-loading {
      .loadingIcon {
        position: absolute !important;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  #page-listing a,
  .list-actions a {
    color: var(--them-color);
  }

  .list-actions {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .wj-state-multi-selected a {
    color: #fff !important;
  }

  .login_v2_form {
    border-color: var(--them-color);
  }

  .icon_selector {
    a {
      border: 1px solid transparent;

      &.selected {
        border: 1px solid var(--them-color) !important; //background-color: var(--them-color) !important;
      }
    }
  }

  .page-content-title {
    background: var(--them-color);
    color: white; // font-size: 15px;
    // font-weight: 400;
  }

  .listing-extra-actions {
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .theme-color {
    color: var(--them-color) !important;
  }

  #nav-mega > ul > li > a.active,
  #nav-mega > ul > li:hover > a,
  #nav > ul > li > a.active,
  #nav > ul > li:hover > a {
    background-color: rgba(0, 0, 0, 0.1);
  }

  // ul.nav.nav-stacked.nav-tabs > li.active {
  //   border-left: 2px solid var(--them-color);
  //   border-top: 1px solid var(--them-color);
  // }
  .wrapper .nav-tab-pills-image ul li .nav-link.active,
  .wrapper .nav-tab-pills-image ul li .nav-link.active:focus,
  .wrapper .nav-tab-pills-image ul li .nav-link.active:hover,
  .wrapper .nav-tab-pills-image ul li .nav-link:focus,
  .wrapper .nav-tab-pills-image ul li .nav-link:hover {
    // border-bottom: 1px solid var(--them-color);
    outline-color: transparent;
    color: var(--them-color);
  }

  .search-ctrl-label:after {
    color: var(--them-color);
  }

  // .static-modal-height > .modal-body > .afaqy-modal-content {
  // min-height: 400px !important;
  // max-height: var(--modal-height) !important;
  // max-height: 100%;
  // }
  // resetting tab content scroll and will add scroll to tabs on the modal component itself
  // .static-modal-height > .modal-body > .afaqy-modal-content .tab-content {
  //   // height: calc(var(--modal-content-height) - 30px);
  //   overflow-y: auto;
  //   overflow-x: hidden;
  // }
  //.cmsmodal .static-modal-height > .modal-body > .afaqy-modal-content {
  //  height: calc(var(--modal-height) - 60px) !important;
  //}
  //.cmsmodal .static-modal-height > .modal-body > .afaqy-modal-content .tab-content {
  //  height: calc(var(--modal-content-height) - 90px) !important;
  //}
  .tab-pane .tab-pane .cmsmodal .modal-background {
    min-height: var(--modal-content-height);
  }

  // #userPermissionsGroups ul.nav-tabs {
  //   height: calc(var(--modal-content-height) - 65px) !important;
  //   overflow-y: auto;
  // }
  // .assign-user-options {
  //   height: calc(var(--modal-content-height) - 100px) !important;
  //   .wijmo-checkbox {
  //     display: inline-block;
  //   }
  // }
  // .form-assignation-form {
  //   .accessUnitGroupTab {
  //     wj-flex-grid {
  //       height: calc(var(--page-content-height) - 260px);
  //     }
  //   }
  //   wj-flex-grid {
  //     height: calc(var(--page-content-height) - 250px);
  //   }
  // }
  .reportSettingsModal {
    .modal-body {
      overflow: auto;
    }
  }

  .reportSettings .page-content-title {
    padding: 7px 10px !important;
  }

  // .report-date-grid .wj-flexgrid {
  //   max-height: calc(var(--page-content-height) - 140px);
  // }
  #FlexGridPopupSelect .wj-flexgrid {
    height: var(--modal-content-height);
  }

  .cms-listing-body {
    wj-flex-grid > div:nth-child(1) {
      max-height: calc(var(--page-content-height) - 150px) !important;
      overflow-x: hidden !important;
    }

    .page-link {
      color: var(--them-color);
    }
  }

  //.flexgridSelect .wj-flexgrid {
  //  height: calc(var(--modal-content-height) - 150px) !important;
  //}
  .flexgridSelect3 .wj-flexgrid {
    height: calc(var(--modal-content-height) - 235px) !important;
  }

  .groups-grid .wj-flexgrid {
    //height: calc(var(--modal-content-height) - 150px) !important;
  }

  // .left-content-form .tab-content {
  //     height: 100%;
  //     min-height: 20vh;
  //     max-height: 45vh;
  //     overflow: auto;
  //     position: relative;
  // }
  .left-content-form .form-assignation-form wj-flex-grid {
    //height: calc(var(--left-content-form-height) - 50px) !important;
  }

  .listing-content .wj-cell.wj-alt.wj-state-selected {
    background-color: #e9e9e9 !important;
    color: #000;
  }

  .listing-content .wj-cell.wj-state-selected {
    background-color: #fff !important;
    color: #000 !important;
  }

  #mapControl_selectTile label.dropdown-item:hover {
    background-color: inherit;
    color: var(--them-color);
  }

  .fancy {
    position: relative;
    background: linear-gradient(to right, var(--them-color), #b5b5b5);
    padding: 0.5rem;
    color: white;
    width: 100%;
    font-weight: 500;
  }

  .tag-primary {
    background: var(--them-color);
  }

  .noUi-connect {
    background: var(--them-color) !important;
  }

  .inversed .noUi-target {
    background: var(--them-color) !important;
  }

  .inversed .noUi-connect {
    background: #fafafa !important;
  }

  .picker .item.selected {
    background: var(--them-color) !important;
    color: #fff;
  }

  .picker .item {
    border: 1px dashed var(--them-color);
  }

  .chatBoxClass {
    .modal-body {
      overflow: hidden;
    }

    .flexgridSelect {
      wj-flex-grid {
        //height: calc(var(--modal-content-height) - 80px) !important;
      }
    }
  }

  .mdi-spin:before {
    animation: mdi-spin 0.5s infinite linear;
  }

  .dataHeading {
    margin-right: auto;
  }

  .wj-header,
  table th {
    font-family: 'Oswald', sans-serif;
    color: white;
    font-weight: lighter;
  }

  table th {
    padding: 3px 5px !important;
  }

  .input-group {
    flex-wrap: nowrap; // height: 37px;
  }

  .input-group-addon {
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;
  }

  .custom-input-group-addon {
    position: absolute;
    right: 0;
    top: 0;
    color: white;
    padding: 0.25rem 0.5rem;
    background: var(--them-color);
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease-in-out;
  }

  .modal-backdrop.show {
    display: none !important;
  }

  #sidebar-key {
    margin-right: 0 !important;
  }

  .sidebar-spacing {
    top: 46px !important;
  }

  .modal-backdrop.user-settings-sidebar {
    background-color: transparent !important;
  }

  .heading {
    font-weight: 500;
    // text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    text-align: left;
  }

  .page-content-title {
    font-family: 'Oswald', sans-serif;
  }

  a:not([href]):not([tabindex]) {
    cursor: pointer;
  }

  .icon-12px {
    font-size: 12px;
  }

  .icon-14px {
    font-size: 14px;
  }

  .icon-18px {
    font-size: 18px;
  }

  .icon-20px {
    font-size: 20px;
  }

  .icon-22px {
    font-size: 22px;
  }

  .icon-24px {
    font-size: 24px;
  }

  .icon-28px {
    font-size: 28px;
  }

  .tippy-tooltip.light-theme {
    padding: 0 !important;
  }

  #content-wrapper {
    // margin-top: 30px;
    margin-left: 0 !important;
  }

  .wj-row {
    .unit-tooltip-container {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  .wj-detail {
    .unit-tooltip-container {
      .card {
        padding: 10px 0;
      }
    }
  }

  .wj-cell {
    padding-left: 5px;

    .checkbox-squared {
      display: inline-block;
    }
  }

  .eventForm {
    .show,
    .hidden {
      width: 100%;
    }
  }

  #left-content {
    position: relative;
  }

  // .options-list {
  //     position: fixed;
  //     left: 0px;
  //     top: 0px;
  //     z-index: 9999;
  //     background: #f3f3f4;
  //     min-width: 100px;
  // }

  // .options-list span {
  //     padding: 5px;
  // }

  .grid-container {
    position: relative;
  }

  .custom-file {
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: 2rem;
    margin-bottom: 0;
    cursor: pointer;
  }

  .custom-file-input {
    min-width: 14rem;
    max-width: 100%;
    height: 2rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
  }

  .custom-file-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2rem;
    padding: 0.5rem 1rem;
    color: #464a4c;
    pointer-events: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    line-height: 1;
    height: 2rem;
  }

  .custom-file-control::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: 2rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #464a4c;
    background-color: #eceeef;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0 0.25rem 0.25rem 0;
    content: attr(alt);
    line-height: 1;
    height: 2rem;
  }

  label.custom-file {
    width: 100%;
  }

  .custom-file-control::after {
    content: attr(title);
  }

  .custom-select {
    height: 2rem;
  }

  .customSelectWithClear {
    .custom-select-container {
      .form-control {
        border-right: none;
        margin-right: 0;
      }
    }
  }

  .custom-select-container {
    // .form-control {
    //   height: 31px;
    //   border-right: none;
    //   margin-right: 0;
    // }
    .customBorderRadius {
      border-radius: 0;
    }

    .customBorderRadius2 {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .header-width {
    display: block !important;
  }

  .hidden {
    display: none !important;
  }

  .tooltip {
    z-index: 999999;
  }

  .radio-button span {
    cursor: pointer;
  }

  .icon-right {
    top: initial;
    bottom: 0px;
    height: 25px;
  }

  #footer {
    height: 27px;
    line-height: 25px;
    width: 100%;
  }

  #site_setting_icon .icon1 {
    top: 5px;
    left: 9px;
    width: 10px;
  }

  #site_setting_icon .icon2 {
    top: 13px;
    left: 15px;
    width: 10px;
  }

  #site_setting_icon .icon3 {
    top: 6px;
    left: 20px;
    width: 8px;
  }

  .no-padding {
    padding: 0;
  }

  .no-padding-left {
    padding-left: 0;
  }

  .no-padding-right {
    padding-right: 0;
  }

  // .pull-right {
  //     float: right;
  // }

  input[type='checkbox'] {
    margin: 0 5px;
  }

  .modal-footer {
    padding-top: 7px;
    padding-bottom: 7px;
    text-align: center;
  }

  unit-form {
    .modal-footer {
      .form-actions {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  // .modal .form-group {
  //   margin-bottom: 0px;
  // }
  .modal .page-content-title,
  .modal .element-form .form-group {
    margin-bottom: 5px;
  }

  // .modal h5 {
  //   line-height: 17px;
  //   padding: 1px;
  //   text-align: center;
  //   font-weight: 400;
  // }
  .wj-cell {
    .cursor-pointer {
      display: inline-block;
      margin-bottom: 0;
    }
  }

  .simple-notification.rtl-mode .icon {
    left: 0;
    right: auto;
  }

  .simple-notification.rtl-mode .sn-title,
  .simple-notification.rtl-mode .sn-content {
    padding: 0 0 0 50px;
  }

  .ol-control button {
    background: var(--them-color);
  }

  // .modalbody {
  //   margin: 10px 5px 0 5px;
  // }
  .modal .btn-file {
    padding: 5px;
  }

  .relative-pos {
    position: relative;
  }

  .preview-clear {
    position: absolute;
    z-index: 7;
    top: 2px;
    right: 2px;
    background: 0 0;
    border: 1px dotted var(--them-color);
    border-radius: 5px;
    margin: 5px;
    text-transform: uppercase;
    padding: 0px 5px;
    font-weight: 700;
    color: var(--them-color);
    transition: all 0.15s linear;
    line-height: 20px;
    letter-spacing: 0.025em;
  }

  .color-inner-box.color-active {
    opacity: 1;
  }

  .header-width .col-xl-9 {
    padding-left: 0;
  }

  .logo {
    margin: 0;
  }

  .disblock {
    display: block !important;
  }

  wj-menu {
    width: 100%;
  }

  .afaqy-mask {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 5000;
    background-color: #ffffff;
    visibility: visible;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1); //color: rgba(255, 255, 255, 0.7);
  }

  .unit-form .tab-container .nav-tabs .nav-link {
    padding: 3px;
  }

  .right-form-layout {
    border-left: 1px solid #ddd;
  }

  // .show {
  //   display: block;
  // }
  .center {
    text-align: center !important;
  }

  .left {
    text-align: left !important;
  }

  .right {
    text-align: right !important;
  }

  .page-heading-buttons {
    padding: 0 3px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    margin-bottom: -1px;
    z-index: 2;
    position: relative;

    .btn-primary {
      color: white !important;
    }

    .btn {
      padding: 7px 5px 3px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      border-radius: 4px 4px 0 0;
    }

    .btn-tab {
      position: relative;
      background: white !important;
      color: var(--them-color) !important;
      border-left: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;

      //border: none;
      //border-top: 2px solid;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        background: var(--them-color);
        width: 100%;
        border-radius: 4px 4px 0 0;
      }
    }

    .btn-tab-inactive {
      color: var(--them-color) !important;
      border: none;
      background: transparent;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      span,
      i {
        padding: 0 5px;
      }
    }
  }

  .form-title {
    text-align: left;
    padding: 10px 10px 5px 10px;
    margin: 0;
    color: var(--them-color);
  }

  #content-wrapper,
  .contain-inner,
  .header-width,
  .site-content-title {
    max-width: 5000px;
  }

  .simple-notification-wrapper.right {
    right: 0px !important;
  }

  .simple-notification-wrapper.top {
    top: 50px !important;
  }

  .simple-notification-wrapper.left {
    left: 0px !important;
  }

  table {
    thead {
      background: linear-gradient(to bottom, var(--them-color) 0%, #000 250%);
      color: white;
    }
  }

  /*    .listOptions {
        // box-shadow: 0 0 15px #909090;
        // padding: 10px;

        table {
            margin: 0;

            tr {
                height: 29px;

                td {
                    padding: 0 5px;
                    vertical-align: middle;

                    label {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
*/
  #lockScreen {
    .modal-dialog {
      max-width: 273px !important;

      input {
        width: 100% !important;
      }
    }
  }

  .form-footer {
    position: relative;
    background: white;
    padding: 5px 0;
    text-align: center;
  }

  .form-footer-border {
    border-top: 2px solid rgba(0, 0, 0, 0.5);
    width: 100%;
    margin: 5px auto;
  }

  .list-header-title {
    margin-left: 2px;
  }

  .flexgridSelect
    .wj-alt:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(
      .wj-state-multi-selected
    ) {
    background: #fff !important;
  }

  .padddig-x {
    padding-left: 5px;
    padding-right: 5px;
  }

  .padddig-y {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .fa-2 {
    font-size: 2em;
  }

  .fa-3 {
    font-size: 4em;
  }

  .fa-4 {
    font-size: 7em;
  }

  .fa-5 {
    font-size: 12em;
  }

  .fa-6 {
    font-size: 20em;
  }

  .fa-1,
  .fa-2,
  .fa-3,
  .fa-4,
  .fa-5,
  .fa-6 {
    margin-right: 0.07142857em;
  }

  .pointer {
    cursor: pointer;
  }

  .remove {
    color: $error-color;
  }

  .unit-form wj-auto-complete {
    max-width: 140px;
  }

  .icon {
    line-height: 0;
  }

  .icon_selector {
    //max-height: 120px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
  }

  .inline {
    display: inline;
  }

  .listing-extra-actions {
    padding: 5px;
  }

  .user-permissions-list h4 {
    color: #000 !important;
  }

  .no-margin {
    margin: 0 !important;
  }

  ul.nav.nav-stacked.nav-tabs {
    float: left;
    //background-color: lightgray !important;
  }

  #userPermissionsGroups
    .parentPermissions
    .nav.nav-stacked.flex-column.nav-tabs {
    float: left;
  }

  ul.nav.nav-stacked.nav-tabs > li {
    margin-left: 0px !important;
  }

  .inner-wijmo-cell {
    margin: -3px;
    padding-bottom: 6px;
  }

  .hide-form-tab-access ul.nav-tabs {
    display: none;
  }

  .modal-draggable-header {
    cursor: move;
  }

  .red-color {
    color: $error-color !important;
  }

  #page-listing-body {
    overflow-y: auto;
  }

  .listing-grid {
    border-radius: 0;
    //padding-top: 15px;
    background: white;
  }

  #monitoring-grid .wj-cell,
  #monitoring-grid .wj-cell.wj-alt {
    border-right: 0; // text-align: center !important
  }

  #monitoring-grid
    .wj-alt:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(
      .wj-state-multi-selected
    ) {
    background: #f9f9f9;
  }

  .afaqy-tooltip {
    position: absolute;
    overflow: auto;
    height: auto;
    width: auto;
    left: 0px;
    top: 0px;
    z-index: 99999;
    display: block;
    padding: 2px; // background: rgba(0, 0, 0, 0.7);
    color: #fff;
    filter: alpha(opacity=85);
    -moz-opacity: 0.85;
    opacity: 0.85; // border-radius: 5px;
  }

  .afaqy-tooltip-body {
    margin: 1px;
    padding: 3px 7px;
    background: rgba(0, 0, 0, 0.6); // border-radius: 5px;
    //font-size: 80%;
  }

  #extraContent {
    background: #fff;

    .markerAccessTab {
      wj-flex-grid {
        //max-height: calc(var(--modal-height) - 245px) !important;
      }
    }

    //.flexgridSelect {
    //  wj-flex-grid {
    //    max-height: calc(var(--modal-height) - 160px) !important;
    //  }
    //}
    // .unitSensorsForm {
    //   wj-flex-grid {
    //     max-height: calc(var(--modal-height) - 150px) !important;
    //   }
    // }
    // .unitGroupFlexGridHeight {
    //   wj-flex-grid {
    //     max-height: calc(var(--modal-height) - 180px) !important;
    //   }
    //   .unitGroupAccessTab {
    //     wj-flex-grid {
    //       max-height: calc(var(--modal-height) - 110px) !important;
    //     }
    //   }
    // }
  }

  .afaqy-tooltip-body .table td,
  .afaqy-tooltip-body .table th {
    padding: 2px;
  }

  .afaqy-tooltip-body .table {
    margin-bottom: 0px !important;
  }

  .grid-image {
    height: 25px;
  }

  .grid-menu-image {
    height: 15px;
    width: 15px;
  }

  .tooltip-img {
    height: 100px;
  }

  .borderless td,
  .borderless th {
    border: none;
  }

  .right-side-bar .right-side-inner-box .settings-sidebox .golden-color {
    background-color: #81b71a;
  }

  #nav-mega > ul > li > a,
  #nav > ul > li > a {
    padding: 0 4px;
  }

  #nav .header-arrow-down,
  #nav-mega .header-arrow-down {
    margin-left: 0;
  }

  .header-right {
    padding-left: 0;
    padding-right: 0;
  }

  .form-control-sm .wj-btn {
    padding-top: 0;
    padding-bottom: 0;
  }

  .left-content-form .tab-pane {
    //margin: 0 21px;
  }

  // .input-group-text {
  //   height: 31px;
  //   padding: 0 0.25rem;
  // }
  .inner-wijmo-cell {
    margin: 0;
  }

  .checkbox-inline {
    display: flex !important;
  }

  .listing-grid
    .wj-state-selected.wj-alt:not(.wj-header):not(.wj-group):not(
      .wj-state-multi-selected
    ) {
    background: #e9e9e9 !important;
    color: #000;
  }

  .follow-container {
    display: flex;
    min-width: 375px;
    min-height: 255px;
    border: solid 1px #121621;
    color: #121621;
  }

  mwlResizable {
    box-sizing: border-box;
  }

  .predefined-reports-list {
    //margin-left: 15px;
    padding: 0 5px;
  }

  .predefined-report-item {
    padding: 1px 10px;
  }

  .report-form {
    .reportsOptions {
      //height: calc(var(--page-content-height) - 120px) !important;
    }
  }

  .w-65 {
    width: 65% !important;
  }

  .list-group-item {
    padding: 5px;
  }

  .reportoptions-fieldset {
    .form-group {
      margin: 0;
    }
  }

  .report-resource-list {
    //max-height: calc(var(--page-content-height) - 132px) !important;
  }

  .max-width-400 {
    max-width: 420px !important;
  }

  .max-width-500 {
    max-width: 35vw !important;
  }

  .searchArea.report-resource-list {
    //height: calc(var(--page-content-height) - 170px) !important;
  }

  .searchBoxCtrl.search-ctrl-label input {
    height: 30px;
    margin-bottom: 10px;
  }

  legend {
    font-size: 100%;
    font-weight: bold;
    padding: 0 10px;
    color: var(--them-color);
  }

  .list-group-item:hover,
  .list-group-item:focus {
    z-index: inherit;
  }

  .page-content-title {
    padding: 5px 10px;
  }

  .reportoptions-fieldset {
    border-bottom: 1px solid #e0e0e0;
    margin: 5px 0;
  }

  .wj-glyph-up {
    position: absolute;
    bottom: 8px; // right: 0;
    border-right: 0.2em solid transparent;
    border-bottom: 0.2em solid;
    border-left: 0.2em solid transparent;
  }

  .wj-glyph-down {
    position: relative;
    border-top: 0.2em solid;
    border-right: 0.2em solid transparent;
    border-left: 0.2em solid transparent;
  }

  .card {
    margin-bottom: 5px !important;
  }

  .card-header {
    padding: 5px !important;
  }

  .picker {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      display: inline-block;
      margin-right: 3px;
      margin-bottom: 3px;
      text-align: center;
      cursor: pointer;
      border-radius: 7px;
      padding: 3px 5px;
      width: 90px;
      color: var(--them-color);
    }
  }

  .chat-container {
    height: 100%;
  }

  //.chat-container {
  //  height: 100%;
  //}
  #trackPoint {
    border-color: var(--them-color);
  }

  .unit-form-custom-2 {
    table {
      thead {
        background: linear-gradient(to bottom, var(--them-color) 0%, #000 250%);
        color: white;
      }
    }
  }

  .ch-35px {
    line-height: 35px;
    vertical-align: middle;
    height: 35px;
  }

  .modal-body {
    overflow-y: auto;
  }
}

cms {
  #header {
    z-index: 2 !important;
  }

  #user-settings-sidebar {
    z-index: 1 !important;
  }
}

.report-history-prograssbar {
  position: relative;
  padding-left: 40px;

  .progress-bar {
    // bar {
    position: absolute;
    left: 0;
    background: #e7e7e7 0% 0% no-repeat padding-box;
    border-radius: 9px;
    width: 35px;
    height: 18px;
    padding-left: 4px;
    // }
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--them-color);
}

.headerCtrl {
  margin-top: 7px;
}

.auditlogPage {
  .pagination-details {
    .page-link {
      padding: 5px 10px;
    }
  }

  .form-group {
    margin-top: 10px !important;

    label {
      margin: 0.5rem 0 !important;
    }
  }

  .dataHeading {
    margin-top: 10px !important;

    .form-group {
      flex: 1;
      margin: 0 !important;
    }
  }
}

.page-link {
  color: var(--them-color);
}

.follow-container {
  .modal-body {
    overflow: hidden;
    background: white;
  }
}

// .customForm {
//   .form-control {
//     border-radius: 0;
//     height: 31px;
//     margin-right: 0;
//   }
// }

#notifications-sidebar {
  // .wj-cell {
  //     padding: 0;
  // }

  .wj-cell:not(.wj-header):not(.wj-group):not(.wj-alt):not(
      .wj-state-selected
    ):not(.wj-state-multi-selected) {
    background: #e9ecef;
  }
}

.invalid-form {
  color: $error-color;
}

.bootstrap.ng2-tag-input {
  padding: 0 5px !important;
  border: 1px solid #cecece !important;
  border-radius: 5px;

  &.ng2-tag-input--focused {
    border: 1px solid var(--them-color) !important;
  }
}

/* styling for dragula to be used in any component in future for dragging and sorting things */
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
  background-color: var(--them-color);

  .form-group {
    margin-top: 5px !important;
    margin-left: 5px !important;
  }

  a {
    color: #fff;
  }
}

.dashboard {
  /* fix for dragging in dashboard */
  .gu-mirror {
    background-color: transparent;
  }
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}

.danger {
  color: $error-color !important;
}

/* reset time picker styling to theme style */
#time-picker-wrapper {
  z-index: 1060 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #time-picker {
    margin: 10vh auto !important;

    .time-picker-header {
      text-align: center;
      background: var(--them-color) !important;

      .time-picker-selected-time,
      .time-picker-selected-ampm {
        div {
          color: #ccc !important;

          &.selected {
            color: #fff !important;
          }
        }
      }
    }

    .time-picker-clock {
      > button.active {
        background: var(--them-color) !important;
      }

      .time-picker-clock-arrow,
      .time-picker-clock-origin {
        background: var(--them-color) !important;

        span {
          background: var(--them-color) !important;
        }
      }
    }

    .time-picker-footer {
      button {
        color: var(--them-color) !important;
        background: none !important;

        &.atp-ref-dialog-close {
          color: #fff !important;
          background: var(--them-color) !important;
        }
      }
    }
  }
}

.selectControl {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
  align-items: center;
}

.atp-time-picker-container {
  position: relative;

  i {
    position: absolute;
    right: 25px;
    top: 8px;
    font-size: 18px;
    font-weight: normal;
    color: var(--them-color);
  }
}

.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:hover {
  background: none !important;
  color: $gray-500 !important;
  border-color: $gray-500 !important;
  cursor: not-allowed;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  background: none !important;
  color: $gray-500 !important;
  border-color: $gray-500 !important;
  cursor: not-allowed;
}

.errors_tooltip {
  &.bs-tooltip-top .arrow::before,
  &.bs-tooltip-auto[x-placement^='top'] .arrow::before {
    border-top-color: $error-color;
  }

  .tooltip-inner {
    color: $white !important;
    background-color: $error-color;

    * {
      text-align: left !important;
      color: $white !important;
    }
  }

  /* styling afaqy tooltip with errors */
  &.afaqy-tooltip {
    .afaqy-tooltip-body {
      background-color: $error-color;
      color: $white !important;

      * {
        text-align: left !important;
        color: $white !important;
      }
    }
  }
}

/* fix for hover close button on modals */
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  color: #fff;
  text-decoration: none;
  opacity: 1;
}

.text-theme-color {
  color: var(--them-color) !important;
}

.bg-primary {
  background-color: var(--them-color) !important;
}

// .form-control,
// .wj-form-control {
//     background-color: linear-gradient(to bottom, white 0%, #f9f9f9 60%);
// }

.btn-sm-custom-height {
  height: calc(1.68125rem + 2px);
  padding: 0.25rem 0.5rem;
}

.btn-sm-custom-height {
  height: calc(1.68125rem + 2px);
  padding: 0.25rem 0.5rem;
}

.dropdown-menu {
  z-index: 1060;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

/* possible fix for wijimo flexgrid and switch checkboxes */
wj-flex-grid {
  background-color: #000;
  .switch {
    .slider {
      transition: none !important;

      &:before {
        transition: none !important;
      }
    }
  }
}

/* fixes for wijmo color input */
wj-input-color {
  width: 100% !important;

  &.form-control-sm {
    .wj-template {
      height: calc(1.68125rem + 2px);

      .wj-input-group {
        border-radius: 0;

        .wj-input-group-btn {
          .wj-btn {
            width: 100%;
            border-radius: 0;

            .wj-glyph-down {
              bottom: auto;
            }
          }
        }
      }
    }
  }
}

.flash-message-reset {
  .alert {
    margin: 0 !important;
    padding: 0.37rem 1.25rem !important;
  }
}

@keyframes leftrightbounce {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(-5px, 0, 0);
  }

  40% {
    transform: translate3d(5px, 0, 0);
  }
}

.leftrightbounce {
  animation-name: leftrightbounce;
  animation-duration: 300ms !important;
}

@keyframes topbottombounce {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(0, -5px, 0);
  }

  40% {
    transform: translate3d(0, 5px, 0);
  }
}

.topbottombounce {
  animation-name: topbottombounce;
  animation-duration: 300ms !important;
}

.ng-resizable-diagonal {
  border-bottom-color: var(--them-color);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--them-color);
}

.unicode-plaintext {
  unicode-bidi: plaintext;
}

.wj-cell {
  min-height: 32px;
}

.linear-bg {
  background: linear-gradient(
    to bottom,
    var(--them-color) 0%,
    #000 250%
  ) !important;
}

.fa-18px-mdi-sibiling-fix {
  padding: 4px;
}

.tippy-box[data-theme~='monitoring-tooltip'] {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;

  .tippy-backdrop {
    background: transparent;
  }

  .tippy-content {
    padding: 0;
    max-height: 55vh;

    .unit-tooltip-container {
      .card-body {
        max-height: calc(55vh - 260px);
      }
    }
  }
}

.monitoring-tooltip-theme {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;

  .tippy-backdrop {
    background: transparent;
  }

  .tippy-content {
    max-height: 55vh;

    .unit-tooltip-container {
      .card-body {
        max-height: calc(55vh - 260px);
      }
    }
  }
}

.monitoring-tooltip-name {
  position: absolute;
  width: 99%;
  height: 35px;
  right: 0;
  top: 0;
  padding-top: 8px !important;
}

.tippy-popper .unit-tooltip-container {
  width: auto !important;
}

.dashboard {
  background: #f7f7f7;

  .wj-columnfiltereditor {
    //max-height: calc(var(--page-content-height) / 2); /* causes problems with small height screens */

    .wj-listbox {
      max-height: 65px !important;
    }
  }
}

.user-select-non {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

input[type='checkbox'] {
  cursor: pointer !important;
}

input[type='time'] {
  cursor: text;

  &::-webkit-inner-spin-button,
  &::-webkit-clear-button {
    -webkit-appearance: none;
    display: none;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
.color-black-override {
  .white {
    fill: #222;
  }
}
dynamic-ng-bootstrap-form {
  .btn-group.btn-group-toggle {
    .btn {
      color: var(--them-color);
      background-color: #fff;
      border: 1px solid var(--them-color);
      &.active {
        color: #fff;
        background-color: var(--them-color);
      }
      &.focus {
        outline: none;
      }
    }
  }

  .checkbox-squared {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .checkbox-squared-content {
      margin-left: 5px;
    }
  }
}

.bs-datepicker {
  border-radius: 5px;

  .bs-datepicker-container {
    padding: 0;
  }

  .bs-datepicker-head {
    background-color: #eef9f9 !important;
    padding: 4px;
    height: 36px;
    font: normal normal normal 12px/22px Roboto;
    color: #0c6566;
    border-radius: 4px 4px 0 0;
  }

  .bs-datepicker-head button {
    color: #0c6566;
  }

  .bs-datepicker-head button[disabled],
  .bs-datepicker-head button[disabled]:hover,
  .bs-datepicker-head button[disabled]:active {
    color: #0c6566;
  }

  .bs-datepicker-body {
    padding: 0;
    min-height: 210px;
    min-width: 270px;
  }

  .bs-datepicker-body table thead {
    background: none;
    color: #3e3e3e;
    font: normal normal bold 10px/13px Segoe UI;
  }

  .bs-datepicker-body table td {
    color: #3d3d3d;
  }
  .bs-datepicker-body table.days span {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .bs-datepicker-body table.days td.active:not(.select-start):before,
  .bs-datepicker-body table.days td.in-range:not(.select-start):before,
  .bs-datepicker-body table.days span.active:not(.select-start):before,
  .bs-datepicker-body table.days span.in-range:not(.select-start):before {
    background-color: #eef9f9;
  }

  .bs-datepicker-body table.days td.select-start + td.select-end:before,
  .bs-datepicker-body table.days td.select-start + td.is-highlighted:before,
  .bs-datepicker-body table.days td.active + td.is-highlighted:before,
  .bs-datepicker-body table.days td.active + td.select-end:before,
  .bs-datepicker-body table.days td.in-range + td.is-highlighted:before,
  .bs-datepicker-body table.days td.in-range + td.select-end:before {
    background: #eef9f9;
    width: 100%;
  }
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*='select-']:after,
.theme-green .bs-datepicker-body table td[class*='select-'] span:after {
  background-color: #0c6566 !important;
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.color-red {
  color: red;
}

.report-history-progressbar {
  b {
    background: #e7e7e7 !important;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    width: 46px !important;
    // height: 20px !important;
    padding-left: 4px;
    color: #9a9898 !important;
    padding: 2px 2px 2px 2px;
    font-size: 13px;
    margin-right: 10px;
  }
  progressbar {
    background-color: #c9c4c4 !important;
    height: 10px;
    padding-left: 0px;
    margin-top: 5px;
  }
}
.reports-view-nested-report-table {
  width: calc(100% - 15px);
  cdk-virtual-scroll-viewport {
    overflow-x: hidden !important;
  }
}

.report-wrapper {
  .ui-table {
    .ui-table-thead {
      > tr {
        > th {
          font-weight: 500;
        }
      }
    }
  }
}

.report-view-virtual-container {
  height: calc(var(--page-content-height) - 175px) !important;
  display: flex;
  > .ui-accordion > cdk-virtual-scroll-viewport {
    height: calc(var(--page-content-height) - 175px) !important;
  }
}

.offline-container {
  .small-font {
    font-size: 11px;
  }
  .offline-color {
    color: #a1a1a1;
  }
  .icon {
    width: 8px;
    height: 8px;
  }

  .device-view-sensor-icon {
    color: #a1a1a1;
    img {
      filter: brightness(0) opacity(30%);
    }
  }
  .device-view-sensor-text {
    color: #a1a1a1;
  }

  .device-view-sensor-text-value {
    color: #a1a1a1 !important;
  }
}

.report-date-grid {
  td.inner-table-container {
    padding: 0 !important;
    margin-left: -2px !important;
  }
}

.reports-container {
  display: flex;
  flex-direction: row;
  .flex-side-left {
    width: 35%;
    transition: all ease-in-out 0.3s;
  }
  .flex-side-right {
    display: flex;
    width: 65%;
    // overflow: auto;
    // justify-content: center;
    // align-items: center;

    report-view {
      width: 100%;
    }
    .toggle-panel {
      width: 35px;
      height: 35px;
      background: var(--them-color);
      border-radius: 0px 17px 18px 0px;
      opacity: 1;
      color: white;
      border: none;
      margin-top: 25px;
      padding-left: 0px;
    }
  }
  &.side-open {
    .flex-side-left {
      margin-left: -35%;
      overflow: hidden;
      transition: all ease-in-out 0.3s;
    }
    .flex-side-right {
      width: 99%;
    }
    .selected-report {
      width: 100%;
    }
    .default-screen-report {
      margin-left: 25px;
      width: 95%;
    }
  }
}
.justify-content-space-between {
  justify-content: space-between;
}
afaqy-report-schedule-list {
  #listing-content {
    height: calc(var(--page-content-height) - 122px) !important;
  }
  .afaqy-flex-grid {
    height: calc(var(--page-content-height) - 120px) !important;
    border-radius: 11px 11px 0px 0px;
  }

  .list-actions {
    a {
      .mdi-delete {
        font-size: 17px !important;
        line-height: 20px !important;
        color: #ff6767 !important;
        margin-right: 0px !important;
      }
    }
  }
}

madinati-list {
  .afaqy-flex-grid {
    height: calc(var(--page-content-height) - 50px) !important;
  }
}
zones-form {
  .afaqy-flex-grid {
    height: calc(var(--page-content-height) - 48px);
  }
}

unit-form-general {
  wj-input-date-time {
    background-color: #e9ecef !important;
  }
  .checkbox-SIMValidation {
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
  }
}

.clear-input-value {
  color: red;
  border: 1px solid red;
  margin: auto 0 auto 7px;
  padding: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

madinati-form {
  .modal-body {
    height: calc(var(--modal-content-height)) !important;
  }
  .afaqy-modal-content,
  .modalbody {
    height: 100% !important;
  }
  .modal-dialog.modal-lg {
    max-width: 50vw;
  }
}

.wj-cell.wj-header {
  text-align: center !important;
  color: #fff !important;
  font-weight: lighter !important;
}

.mbx-9 {
  margin-bottom: 0.9rem;
}
.pxl-9 {
  padding-left: 0.9rem;
}

.pbl-9 {
  padding-bottom: 0.9rem;
}

.pxl-4 {
  padding-left: 0.45rem;
  padding-right: 0.45rem;
}

.last-known-icon {
  display: flex;
  width: 12px !important;
  height: 12px !important;
  svg {
    display: flex;
    width: 12px !important;
    height: 12px !important;
  }
}

.iotMonitoringMockSocketUpdates {
  position: absolute;
  top: 100px;
  right: 100px;
  z-index: 9999;
}
