@import './variables';

:root[dir='rtl'] {
  --direction-primary: right;
  --direction-secondary: left;
  --direction-absolute-left: auto;
  --direction-absolute-right: 0;
  --direction-multiplier: -1;
  --transformOrigin: 100% 0;

  direction: rtl;

  body {
    text-align: right;
  }

  .form-input input ~ .label {
    top: 1.5rem;
    right: 0 !important;
    left: auto;
    display: inline;
    padding: 0.2em 2.2em 0.6em 0.3em;
    text-align: right !important;
  }

  .altBody {
    .form-input > .mdi ~ .label {
      margin-right: 2.25rem !important;
      margin-left: 0 !important;
    }

    input,
    .underline {
      margin-right: 2.25rem;
      margin-left: 0 !important;
      width: calc(100% - 2.25rem);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  a,
  div,
  .form-control {
    font-family: 'Cairo', sans-serif;
    text-align: right;
  }

  .predefiend-reports-list {
    right: 15px;
    position: relative;

    .p-fixed {
      right: auto;
      left: 15px;
    }
  }

  .wj-glyph-down-left {
    visibility: hidden;
  }

  .listOptions {
    table {
      label {
        display: flex;
        align-items: center;
      }
    }
  }

  .heading,
  .form-group,
  table.borderless tbody {
    text-align: right !important;
  }

  .left-content-form {
    .form-footer {
      left: 0;
      text-align: left;
    }
  }

  .stats {
    text-align: right;
  }

  .dataHeading {
    margin-right: 0;
    margin-left: auto;
  }

  .td {
    text-align: right;
  }

  .ol-viewport {
    .ol-zoom.ol-unselectable.ol-control {
      direction: ltr !important;
    }
  }

  .ol-zoom {
    right: 2px;
    left: auto;
  }

  #mapControl_layerVisibility {
    left: auto;
    right: 2px;
  }

  #main-map-selectTile {
    left: auto;
    right: 2px;
  }

  #mapControl_utilities {
    left: auto;
    right: 2px;
  }

  .wj-cell {
    border-left: none !important;
  }

  #page-listing-body {
    overflow-y: hidden;
  }

  .icon-gps-state-low:after,
  .icon-gps-state-high:after {
    margin-right: -6px;
  }

  .navbar {
    .brand-shape {
      margin-right: 0 !important;
      margin-left: 22px !important;

      &::before {
        left: -22px !important;
        right: auto !important;
        border-width: 35px 0 0 22px !important;
        filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.5)) !important;
      }
    }

    .brand-shape-inverted {
      margin-left: 0 !important;
      margin-right: auto !important;

      &::before {
        right: -22px !important;
        left: auto !important;
        border-color: #fff transparent !important;
        border-width: 35px 22px 0 0 !important;
      }

      .nav-right-buttons {
        li {
          border-left: 1px solid $gray-250;

          &:last-child {
            border-left: none;
          }
        }
      }
    }

    .current-tab {
      &::before {
        left: auto !important;
        right: -22px !important;
        border-width: 0 22px 30px 0 !important;
      }
    }
  }

  #notifications-sidebar,
  #user-settings-sidebar {
    .modal-content {
      box-shadow: 4px 0 4px rgba(0, 0, 0, 0.2) !important;
    }
  }

  #sidebar {
    .nav-link.dropdown-toggle {
      &::after {
        left: 5px !important;
        right: auto !important;
      }
    }
  }

  #sidebar.fade {
    .modal-dialog {
      right: -240px !important;
      left: auto !important;
      transition: opacity 0.3s linear, right 0.3s ease-out !important;
    }
  }

  #sidebar.fade.show .modal-dialog {
    right: 0 !important;
  }

  .modal.right.fade .modal-dialog {
    left: -205px !important;
    right: auto !important;
    transition: opacity 0.3s linear, left 0.3s ease-out !important;
  }

  .modal.right.fade.show .modal-dialog {
    left: 0 !important;
  }

  #user-settings-sidebar {
    .userMenu {
      li .heading {
        i {
          margin-right: 0 !important;
          margin-left: 7px !important;
        }
      }
    }
  }

  // .mr-auto {
  //     margin-left: auto !important;
  // }

  // .ml-auto {
  //     margin-right: auto !important;
  // }

  // .mr-2 {
  //     margin-left: 0.5rem !important;
  // }

  // .ml-2 {
  //     margin-right: 0.5rem !important;
  // }

  .list-group {
    padding: 0;

    li.list-group-item {
      a {
        text-align: right;
      }

      .dropdown-item {
        text-align: right;
      }
    }
  }

  .atp-time-picker-container i {
    right: auto;
    left: 25px;
  }

  .nav-tabs {
    padding-right: 0;
  }

  .pl-0 {
    padding-right: 0 !important;
  }

  .wj-cell {
    border-left: 1px solid #c6c6c6 !important;
    border-right: none !important;
  }
}

// .cssrtl .pull-left {
//     float: right;
// }

// .cssrtl .pull-right {
//     float: left;
// }

.cssrtl .options-list {
  left: auto;
}

.cssrtl .export-actions {
  left: 0 !important;
  right: inherit !important;
}

.cssrtl .no-padding-left {
  padding-right: 0;
}

.cssrtl .no-padding-right {
  padding-left: 0;
}

.cssrtl .wj-columnfiltereditor {
  min-width: 230px !important;
}

.cssrtl .contain-error h1 {
  letter-spacing: normal !important;
}

.cssrtl .header-width .col-xl-9 {
  padding-right: 0;
}

.cssrtl #nav li:hover > ul,
#nav-mega li:hover > ul {
  left: auto;
}

// .cssrtl .modal .modal-content .modal-footer .btn {
//     float: none;
// }

.cssrtl .custom-file-control::before {
  right: auto;
  left: -1px;
}

.cssrtl {
  .loginAsMenu {
    right: auto !important;
    left: 0 !important;
  }

  #site_setting_icon {
    .icon1 {
      top: 5px;
      right: 9px;
      width: 10px;
    }

    .icon2 {
      top: 13px;
      right: 15px;
      width: 10px;
    }

    .icon3 {
      top: 6px;
      right: 20px;
      width: 8px;
    }
  }

  #site_setting_icon .right-form-layout {
    border-right: 1px solid #ccc;
  }

  // Modal Localization
  // .text-left {
  //     text-align: right !important;
  // }

  // .flex-row-reverse {
  //     flex-direction: row !important;
  // }

  .modal-header {
    h5 {
      &.modalDrag {
        text-align: right !important;
      }
    }
  }
}

html[dir='rtl'] {
  // .ml-auto {
  //     margin-left: 0 !important;
  //     margin-right: auto !important;
  // }

  .form-group {
    .form-group-hint {
      .hint {
        right: auto;
        left: 25px;
      }
    }
  }

  .modal-dialog {
    .modal-body {
      .nav-tabs {
        .nav-item {
          > .nav-link {
            font-family: 'Cairo', sans-serif;
          }
        }
      }
    }

    .modal-header {
      h5 {
        font-family: 'Cairo', sans-serif;
      }
    }
  }

  #time-picker-wrapper {
    #time-picker {
      .time-picker-header {
        text-align: center;

        .time-picker-selected-time {
          display: flex;
          flex-flow: row-reverse;
          justify-content: center;
          text-align: center;
        }

        .time-picker-selected-ampm {
          text-align: center;
        }
      }
    }
  }

  .right-form-layout {
    border-right: 1px solid #ddd;
    border-left: none;
  }

  .type-row.type-row-children::before {
    left: -1px;
    right: auto;
  }

  /* styling fix for wj-input-color on rtl */
  .wj-inputcolorbox {
    left: auto !important;
    right: 6px !important;
  }

  .wj-input-group .wj-form-control {
    padding-right: 24px;
    padding-left: 8px;
  }

  .modal-header {
    .close {
      margin: -1rem auto -1rem -1rem;
    }
  }

  #userPermissionsGroups
    .parentPermissions
    .nav.nav-stacked.flex-column.nav-tabs {
    float: right;
  }

  // .btn-group,
  // .btn-group-vertical {
  //     flex-flow: row-reverse;
  // }

  caption {
    text-align: right;
  }

  .form-input > .mdi ~ input,
  .form-input > .mdi ~ .label,
  .form-input > .mdi ~ .underline {
    margin-left: 0 !important;
    margin-right: 2.25rem !important;
  }

  .loginContainer {
    .loading-bar-fixed {
      > div#loading-bar-spinner {
        position: fixed;
        right: auto !important;
        left: 115px !important;
        top: 20px !important;
      }
    }
  }

  .loadingbar-container {
    position: absolute;
    height: 6px;
    top: 30px;
    right: 235px;
    left: 240px;
    width: calc(100% - 240px - 235px);
    z-index: 2;

    .loading-bar-fixed {
      > div#loading-bar {
        position: relative !important;

        .bar {
          position: relative !important;
        }
      }
    }

    .loading-bar-fixed {
      > div#loading-bar-spinner {
        position: absolute !important;
        right: auto !important;
        left: 45px !important;
        top: -23px !important;
        color: #fff !important;
      }
    }
  }

  .dragged-section {
    left: 0;
    right: auto;
  }

  .dropdown-toggle::after {
    margin-right: 0.255em;
    margin-left: 0;
  }

  wj-calendar {
    .wj-calendar-header .wj-btn-group {
      display: flex;
      flex-direction: row-reverse;
    }

    .wj-calendar-header {
      display: flex;
      justify-content: space-between;
    }
  }

  .time-static-direction {
    display: flex;
    flex-direction: row-reverse;
  }

  multi-unit-follow-list {
    .panel .panel-primary {
      .card-header {
        padding: 6px 35px 6px 15px !important;

        &::before {
          right: 22px;
          left: auto;
          margin-right: -2px;
          margin-left: auto;
        }

        &::after {
          left: auto;
          right: 15px;
        }
      }

      .panel-collapse {
        .item {
          > .open {
            margin-left: 5px;
          }
        }
      }
    }

    .add-screen {
      left: 0;
      right: auto;
    }
  }

  multi-unit-follow-single-screen .follow-box {
    .main-map-selectTile {
      left: 63px;
      right: auto;
    }

    .mapControl_utilities {
      left: 94px;
      right: auto;
    }

    .box-head {
      .right-menu {
        left: 5px;
        right: auto;

        .menu-item {
          float: left;
          margin-right: 4px;
          margin-left: 0;
        }
      }

      .title {
        border-radius: 0 0 0 5px;
      }
    }
  }
  .mapControl_utilities {
    right: 2px;
    left: auto;
  }
  .main-map-selectTile {
    left: auto;
    right: 2px;
  }
  .dropdown-menu-right {
    left: -27px;
    right: auto;
  }
  .mdi-arrow-expand-left,
  .mdi-arrow-expand-right {
    transform: rotate(180deg);
    display: block;
  }

  ::ng-deep dashboard-chart-view {
    .dropdown-menu-right {
      left: 0 !important;
      right: auto !important;
    }
  }
}
