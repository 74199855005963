/* md up start */
@include media-breakpoint-up(lg) {
  .form-control-lw {
    width: 65%;
  }

  .modal-norm {
    min-width: 50vw;
  }

  .modal-lg {
    max-width: 65vw;
  }

  .secondLayer {
    .modal-lg {
      max-width: 55vw !important;
    }
  }
}

/* md up end */
/* md down start */
@include media-breakpoint-down(lg) {
  .notificationsSlider {
    display: none !important;
  }
}

/* md down end */
