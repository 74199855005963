/**
change icons set color scheme below, this will affect all icons in the set
 */
.red {
  fill: #ed1c24;
}
.green {
  fill: #228343;
}
.yellow {
  fill: #f7d00d;
}
.white {
  fill: #ffffff;
}
.gray {
  fill: #a7a9ac;
}

/**
    icons or parts of icons could be animated with css animation like example below
 */
.animate-example {
  animation-name: fade-img;
  animation-iteration-count: infinite;
  animation-duration: 0.5s;
  transition: all 0.5s ease;
  animation-direction: alternate;
}
@keyframes fade-img {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
}
