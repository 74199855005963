@font-face {
  font-family: 'afaqy-custom';
  src: url('../font/afaqy-custom.eot?41524777');
  src: url('../font/afaqy-custom.eot?41524777#iefix')
      format('embedded-opentype'),
    url('../font/afaqy-custom.woff2?41524777') format('woff2'),
    url('../font/afaqy-custom.woff?41524777') format('woff'),
    url('../font/afaqy-custom.ttf?41524777') format('truetype'),
    url('../font/afaqy-custom.svg?41524777#afaqy-custom') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'afaqy-custom';
    src: url('../font/afaqy-custom.svg?41524777#afaqy-custom') format('svg');
  }
}
*/

[class^='ci-']:before,
[class*=' ci-']:before {
  font-family: 'afaqy-custom';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.ci-key:before {
  content: '\e800';
} /* '' */
.ci-moving-outline:before {
  content: '\e801';
} /* '' */
.ci-no-messages:before {
  content: '\e802';
} /* '' */
.ci-arrows-cw:before {
  content: '\e803';
} /* '' */
.ci-moving-key-on:before {
  content: '\e804';
} /* '' */
.ci-stationary-engine-off:before {
  content: '\e805';
} /* '' */
.ci-stationary-engine-on:before {
  content: '\e806';
} /* '' */
.ci-moving:before {
  content: '\e807';
} /* '' */
