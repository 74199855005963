/* xs up start */

/* xs up end */
/* xs down start */
@include media-breakpoint-down(xs) {
  .modal-row2 {
    max-width: 450px;
  }

  .modal-dialog {
    // max-width: 600px;
    // margin: 1.75rem auto;
  }

  .responsive {
    .brand-container {
      &.NotificationsOpen,
      &.UserSidebarOpen {
        left: -100%;
        position: absolute;
      }
    }
  }
}

/* xs down start */
