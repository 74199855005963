#map-content {
  height: 100%;
}

.mapbox {
  width: 100%;
  height: 100%;
  position: relative;
}

.mapbox button {
  width: 23px;
}

.mapbox .modal button {
  width: auto;
}

.fill {
  width: 100%;
  height: 100%;
}

.main-map-selectTile {
  top: 63px;
  left: 1.25px;
  position: absolute;
  padding: 2px;
  z-index: 1;
}

.follow-map-selectTile {
  display: inline-block;
}

.mapControl_layerVisibility_layerBtn {
  box-shadow: 1px 1px 3px #cecece;
}

.main-map-selectTile .mapControl_selectTile_slider {
  font-size: 1.1em;
  box-shadow: 1px 1px 3px #cecece;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 22.56px;
  width: 23px;
  line-height: 0.4em;
  border: none;
  border-radius: 2px;
  margin-left: 1px;
  background: #f8f9fa;
}

.follow-map-selectTile .mapControl_selectTile_slider {
  padding: 0px;
  height: auto;
  line-height: inherit;
  font-size: inherit;
}

.main-map-selectTile .dropdown-menu {
  left: 30px;
  top: 0px;
  width: 200px;
}

.follow-map-selectTile .dropdown-menu {
  left: 35px;
  top: 30px;
  width: 200px;
  display: block;
}

.mapControl_selectTile .dropdown-menu div {
  left: 30px;
  top: 0px;
  width: 200px;
  cursor: pointer;
}

.primary-dropdown .dropdown-menu .dropdown-item.active {
  color: #ffffff;
}

.ol-zoom {
  top: 7px;
  left: 2px;
}

.ol-zoom button {
  display: inline-block;
}

.ol-zoomslider {
  width: 215px;
  height: 17px;
  padding: 0px 0px;
  border-radius: 20px;
  position: initial;
  display: inline-block;
  overflow: visible !important;
  vertical-align: text-top;
  text-align: left;
}

.ol-zoomslider-thumb {
  height: 15px !important;
  width: 15px !important;
  margin: 0 !important;
  border-radius: 20px !important;
}

#mapControl_layerVisibility {
  position: absolute;
  top: 40px;
  left: 2px;
  padding: 2px;
  z-index: 1;
}

#mapControl_layerVisibility_layers {
  display: inline-block;
}

.mapControl_layerVisibility_btn {
  color: #fff;
  font-size: 1.1em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  line-height: 0.4em;
  border: none;
  border-radius: 2px;
  margin-left: 1px;
  padding: 0px;
}

.mapControl_utilities {
  position: absolute;
  top: 86px;
  left: 2.25px;
  padding: 2px;
  z-index: 1;
}

.mapControl_utilities .mapControl_utilities_btn {
  display: block;
  /* color: #fff; */
  box-shadow: 1px 1px 3px #cecece;
  font-size: 1.14em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  line-height: 0.4em;
  border: none;
  border-radius: 2px;
  margin-top: 1px;
  padding: 3px;
}

.mapControl_utilities_btn_active {
  background-color: rgba(4, 44, 255, 0.5) !important;
}

.mapControl_utilities_getLonLat_content {
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
  z-index: 1;
  direction: ltr;
}

.mapControl_utilities_getLonLat_content span {
  position: relative;
  background-color: rgba(25, 25, 25, 0.5);
  font-size: 22px;
  font-weight: bold;
  color: white;
}

.show-inline {
  display: inline-block;
}
