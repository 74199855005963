/**
  Icon helper to set width and height
  for icons when it's attribute size is set to specific sizes defined
 */
mat-icon {
  &.avl-icon {
    $sizes: 12, 13, 14, 16, 18, 20, 22, 24;

    @each $size in $sizes {
      &[size='#{$size}px'] {
        width: $size + px;
        height: $size + px;

        svg {
          width: $size + px;
          height: $size + px;
        }
      }
    }
  }
}
