.modal-dialog .form-group {
  margin-bottom: 1rem;
}

.mat-step-header {
  user-select: none;

  .mat-step-icon {
    background-color: var(--them-color);
  }
}

.mat-horizontal-content-container {
  overflow: unset !important;
}
