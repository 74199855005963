@font-face {
  font-family: HostingFont;
  src: url('fonts/hostingFont.eot');
  src: url('fonts/hostingFont.eot?#iefix') format('embedded-opentype'),
    url('fonts/hostingFont.eot') format('application/font-woff'),
    url('fonts/hostingFont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* =================== icons ===================*/
.icon-remove-head,
.icon-remove,
.icon-dash {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  color: #f44336;
  cursor: pointer;
}

.icon-ok,
.icon-add {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  color: #4caf50;
  cursor: pointer;
  display: inline-block;
}

.icon-config-head,
.icon-config,
.icon-config-block,
.icon-copy-head,
.icon-copy,
.icon-zone-head,
.icon-area,
.icon-log-msg,
.icon-report-head,
.icon-photo-head,
.icon-photo,
.icon-event-head,
.icon-sms-head,
.icon-msg-head,
.icon-device,
.icon-device-group,
.icon-job-head,
.icon-notification-head,
.icon-location-head,
.icon-user,
.icon-tag-head {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  /*color: #616161;*/
}

.icon-sms,
.icon-copy,
.icon-config,
.icon-config-block,
.icon-eye,
.icon-msg,
.icon-report,
.icon-event {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  /*color: #616161;*/
  cursor: pointer;
}

.icon-sms:hover,
.icon-copy:hover,
    /* .icon-config:hover, */
.icon-config-block:hover,
.icon-eye:hover,
.icon-msg:hover,
.icon-report:hover,
.icon-event:hover,
#group_manipulation_add_add_label:hover,
#group_manipulation_add_rem_label:hover,
#group_manipulation_rem_add_label:hover,
#group_manipulation_rem_rem_label:hover {
  color: #d41e46;
}

.icon-job-head:after {
  content: '\e60D';
}

.icon-notification-head:after {
  content: '\e60E';
}

.icon-remove-head:after {
  content: '\e978';
}

.icon-remove:after {
  content: '\e929';
}

.icon-config-head:after {
  content: '\e975';
}

.icon-config:after {
  content: '\e976';
}

.icon-config-block:after {
  content: '\e977';
}

.icon-copy-head:after {
  content: '\e97D';
}

.icon-copy:after {
  content: '\e97e';
}

.icon-sms-head:after {
  content: '\e93c';
}

.icon-sms:after {
  content: '\e96d';
}

.icon-user:after {
  content: '\e60f';
}

.icon-loginas {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-loginas:hover {
  color: #2196f3;
}

.icon-loginas:after {
  content: '\E99C';
}

.icon-device:after {
  content: '\e610';
}

.icon-device-group:after {
  content: '\e611';
}

.icon-eye:after {
  content: '\e9d1';
  /*color: #2196f3;*/
}

.icon-area:after {
  content: '\e9c4';
}

.icon-msg-head:after {
  content: '\e606';
}

.icon-msg:after {
  content: '\e966';
}

.icon-log-msg:after {
  content: '\E9D0';
}

.icon-zone-head {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
}

.icon-zone-line,
.icon-zone-circle,
.icon-zone-polygone {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  color: #2196f3;
}

.icon-zone-in,
.icon-zone-out,
.icon-zone-empty,
.icon-zone-begin,
.icon-zone-end {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  color: #2196f3;
}

.icon-zone-head:after {
  content: '\E609';
}

.icon-zone-line:after {
  content: '\e981';
}

.icon-zone-circle:after {
  content: '\e97f';
}

.icon-zone-polygone:after {
  content: '\e980';
}

.icon-zone-in:after {
  content: '\e9d5';
}

.icon-zone-out:after {
  content: '\e9d6';
}

.icon-zone-empty:after {
  content: '\e9d4';
}

.icon-zone-begin:after {
  content: '\e9d9';
}

.icon-zone-end:after {
  content: '\e9da';
}

.icon-route-on,
.icon-route-off,
.icon-route-arrow,
.icon-route-head {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
}

.icon-route-on,
.icon-route-off {
  cursor: pointer;
}

.icon-route-on:hover {
  color: #d41e46;
}

.icon-route-head:after {
  content: '\e605';
}

.icon-route-on:after {
  content: '\e964';
}

.icon-route-off:after {
  content: '\e965';
  color: #f44336;
}

.icon-route-arrow:after {
  content: '\E9C3';
}

.icon-location-head:after {
  content: '\e953';
}

.icon-location:after {
  content: '\e953';
  color: #2196f3;
}

.icon-photo-head:after {
  content: '\e968';
}

.icon-photo:after {
  content: '\e969';
}

.icon-event-head:after {
  content: '\e96e';
}

.icon-event:after {
  content: '\e96f';
}

.icon-report-head:after {
  content: '\e607';
}

.icon-report:after {
  content: '\e967';
}

.icon-gps-state-high,
.icon-gps-state-low,
.icon-gps-state-middle,
.icon-gps-state-quater {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  display: inline-block;
  overflow: hidden;
  width: 8px;
}

.icon-gps-state-head {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  /*color: #616161;*/
}

.icon-gprs-state-head:after {
  content: '\e95e';
}

.icon-gprs-state {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  color: #b4b4b4;
}

.icon-gprs-state:after {
  content: '\e95f';
}

.icon-gps-state-head:after {
  content: '\e95b';
}

.icon-gps-state-low:after {
  content: '\e95d';
  margin-left: -6px;
  color: #f44336;
}

.icon-gps-state-middle:after {
  content: '\E9D8';
  margin-left: -6px;
  color: #ff9100;
}

.icon-gps-state-quater:after {
  content: '\E9D7';
  margin-left: -6px;
  color: #ffeb3b;
}

.icon-gps-state-high:after {
  content: '\e95c';
  margin-left: -6px;
  color: #4caf50;
}

.icon-tag-head:after {
  content: '\E933';
}

.icon-driver-head,
.icon-drivers {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  /*color: #616161;*/
}

.icon-driver-head:after {
  content: '\e60b';
}

.icon-drivers:after {
  content: '\E973';
}

.icon-trailer-head,
.icon-trailers {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  /*color: #616161;*/
}

.icon-trailer-head:after {
  content: '\e60c';
}

.icon-trailers:after {
  content: '\e974';
}

.icon-row-move,
.icon-row-up,
.icon-row-down {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  color: #2196f3;
  cursor: pointer;
}

.icon-row-down {
  display: inline-block;
  -moz-transform: rotate(180deg);
  /* Для Firefox */
  -ms-transform: rotate(180deg);
  /* Для IE */
  -webkit-transform: rotate(180deg);
  /* Для Safari, Chrome, iOS */
  -o-transform: rotate(180deg);
  /* Для Opera */
  transform: rotate(180deg);
}

.icon-row-move:after {
  content: '\e98C';
}

.icon-row-up:after {
  content: '\E934';
}

.icon-row-down:after {
  content: '\E934';
}

.icon-dash:after {
  content: '\e98d';
}

.icon-ok:after {
  content: '\e949';
}

.icon-add:after {
  content: '\E979';
}

.icon-tree-collapse,
.icon-tree-expand {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-tree-collapse:after {
  content: '\E9BA';
}

.icon-tree-expand:after {
  content: '\E9B9';
}

.icon-device-move-head,
.icon-device-move,
.icon-device-stop,
.icon-device-move-sensor,
.icon-device-stop-sensor,
.icon-device-move-lbs,
.icon-device-stop-no-msg {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
}

.icon-device-move-sensor,
.icon-device-stop-sensor {
  color: #b4b4b4;
}

.icon-device-move-head:after {
  content: '\e954';
}

.icon-device-move,
.icon-device-stop {
  color: #b4b4b4;
}

.icon-device-move:after {
  content: '\e955 ';
}

.icon-device-stop:after {
  content: '\e956';
}

.icon-device-move-sensor:after {
  content: '\e957';
}

.icon-device-stop-sensor:after {
  content: '\e958';
}

.icon-device-move-lbs:after {
  content: '\e959';
}

.icon-device-stop-no-msg:after {
  content: '\e95a';
}

.icon-sensor-head,
.icon-sensor-uknown,
.icon-sensor-error,
.icon-sensor-text {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
}

.icon-sensor-head:after {
  content: '\e960';
}

.icon-sensor-uknown:after {
  content: '\e961';
}

.icon-sensor-error:after {
  content: '\e963';
  color: #ff9100;
}

.icon-sensor-text:after {
  content: '\e962';
  color: #2196f3;
}

.icon-watch-small,
.icon-watch-medium,
.icon-watch-head,
.icon-watch,
.icon-watch-map,
.icon-gprs-state-head,
.icon-chk {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  /*color: #616161;*/
  cursor: pointer;
}

.icon-watch-small:hover,
.icon-watch-medium:hover,
.icon-watch-head:hover,
    /* .icon-gprs-state-head:hover, */
.icon-watch:hover,
.icon-watch-map:hover {
  color: #ffc107;
}

.icon-watch-head:after {
  content: '\e970';
}

.icon-watch.active:after {
  content: '\e971';
}

.icon-watch.active {
  color: #4caf50;
}

.icon-watch:after {
  content: '\e972';
}

input[disabled] ~ .icon-watch {
  cursor: default;
  color: #bdbdbd;
}

input:checked ~ .icon-watch,
input:checked ~ .icon-watch:hover {
  color: #4caf50;
}

input:checked ~ .icon-watch:after {
  content: '\e971';
}

.icon-watch-medium:after {
  content: '\e931';
}

.icon-watch-small:after {
  content: '\e9c2';
}

.icon-watch-map:after {
  content: '\E9D2';
}

input ~ .icon-chk:after {
  content: '\e9b6';
}

input[disabled] ~ .icon-chk {
  color: #bdbdbd;
  cursor: default;
}

input:checked ~ .icon-chk:after {
  content: '\e9b7';
}

input:indeterminate ~ .icon-chk:after {
  content: '\e9b8';
}

.icon-bind {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  color: #4caf50;
}

.icon-unbind {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  color: #f44336;
}

.icon-bind:after {
  content: '\e982';
}

.icon-unbind:after {
  content: '\e9c1';
}

.icon-cmd-head {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
}

.icon-cmd-exec,
.icon-cmd-exec-gprs {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-cmd-exec:hover,
.icon-cmd-exec-gprs:hover {
  color: #d41e46;
}

.icon-cmd-head:after {
  content: '\e96a';
}

.icon-cmd-exec:after {
  content: '\e96b';
}

.icon-cmd-exec-gprs:after {
  content: '\e96c';
}

.icon-play,
.icon-pause,
.icon-stop,
.icon-play-start,
.icon-play-end,
.icon-play-prev,
.icon-play-next {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-play-start:hover,
.icon-play-end:hover,
.icon-play-prev:hover,
.icon-play-next:hover {
  color: #d41e46;
}

.icon-play:after {
  content: '\e91f';
  color: #4caf50;
}

.icon-pause:after {
  content: '\e925';
  color: #f44336;
}

.icon-stop:after {
  content: '\e9d3';
  color: #f44336;
}

.icon-play-prev,
.icon-play-start {
  display: inline-block;
  -moz-transform: rotate(180deg);
  /* Для Firefox */
  -ms-transform: rotate(180deg);
  /* Для IE */
  -webkit-transform: rotate(180deg);
  /* Для Safari, Chrome, iOS */
  -o-transform: rotate(180deg);
  /* Для Opera */
  transform: rotate(180deg);
}

.icon-play-start:after {
  content: '\E920';
}

.icon-play-end:after {
  content: '\E920';
}

.icon-play-prev:after {
  content: '\e930';
}

.icon-play-next:after {
  content: '\e930';
}

.icon-mon-menu-head {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
}

.icon-mon-menu {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-mon-menu:hover {
  color: #d41e46;
}

.icon-mon-menu-head:after {
  content: '\e97b';
}

.icon-mon-menu:after {
  content: '\e97c';
}

.icon-color-mode-table,
.icon-color-mode-scale {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-color-mode-table:hover,
.icon-color-mode-scale:hover {
  color: #d41e46;
}

.icon-color-mode-table:after {
  content: '\e9dc';
}

.icon-color-mode-scale:after {
  content: '\e91b';
}

.icon-export-pdf,
.icon-export-excel,
.icon-export-file,
.icon-print,
.icon-refresh {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-export-pdf:hover,
.icon-export-excel:hover,
.icon-export-file:hover,
.icon-print:hover,
.icon-refresh:hover {
  color: #d41e46;
}

.icon-export-excel:after {
  content: '\E9C6';
  color: #4caf50;
}

.icon-export-pdf:after {
  content: '\E9C5';
  color: #f44336;
}

.icon-export-file:after {
  content: '\E9C7';
}

.icon-print:after {
  content: '\E9C8';
}

.icon-zoom-in,
.icon-zoom-out {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
  color: #2196f3;
}

.icon-zoom-in:after {
  content: '\E9CA';
}

.icon-zoom-out:after {
  content: '\E9Cb';
}

.icon-zoom-custom:after {
  content: '\E9Cc';
}

.icon-earth {
  content: '\E90E';
}

.icon-right,
.icon-left,
.icon-up,
.icon-down {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-up:hover,
.icon-down:hover {
  color: #d41e46;
}

.icon-up:after {
  content: '\E90D';
  display: inline-block;
  -moz-transform: rotate(180deg);
  /* Для Firefox */
  -ms-transform: rotate(180deg);
  /* Для IE */
  -webkit-transform: rotate(180deg);
  /* Для Safari, Chrome, iOS */
  -o-transform: rotate(180deg);
  /* Для Opera */
  transform: rotate(180deg);
}

.icon-down:after {
  content: '\E90D';
}

.icon-right:after {
  content: '\E9B3';
}

.icon-left:after {
  display: inline-block;
  -moz-transform: rotate(180deg);
  /* Для Firefox */
  -ms-transform: rotate(180deg);
  /* Для IE */
  -webkit-transform: rotate(180deg);
  /* Для Safari, Chrome, iOS */
  -o-transform: rotate(180deg);
  /* Для Opera */
  transform: rotate(180deg);
  content: '\E9B3';
}

.icon-search {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
}

.icon-search:after {
  content: '\E600';
}

.icon-display-groups,
.icon-display-units,
.icon-display-all {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-display-groups:hover,
.icon-display-units:hover,
.icon-display-all:hover {
  color: #d41e46;
}

.icon-display-groups:after {
  content: '\E913';
}

.icon-display-units:after {
  content: '\E912';
}

.icon-display-all:after {
  content: '\E915';
}

.icon-doc {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
  color: #2196f3;
  float: right;
}

.icon-doc:after {
  content: '\E9B4';
}

.icon-download {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-download:after {
  content: '\E9E0';
}

.icon-download:hover {
  color: #d41e46;
}

.icon-triangle-down {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
}

.icon-triangle-down:after {
  content: '\E97A';
}

/* ============= cms ================ */
.icon-diagramma,
.icon-log,
.icon-restore {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-log {
  color: #b4b4b4;
}

.icon-log:hover {
  color: #808081;
}

.icon-log:after {
  content: '\E9BC';
}

.icon-diagramma:after {
  content: '\e967';
}

.icon-account-back,
.icon-billing-back {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  color: #bdbdbd;
  font-size: 32px;
}

.icon-restore:after {
  content: '\e99d';
}

.icon-refresh:after {
  content: '\E918';
  color: #2196f3;
}

.icon-user-note {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  color: #616161;
  cursor: pointer;
}

.icon-user-note:hover {
  color: #2196f3;
}

.icon-user-note:after {
  content: '\E9DD';
}

.icon {
  font-family: HostingFont !important;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  /*color: #616161;*/
}

.pointer {
  cursor: pointer;
}

.icon-rotate {
  display: inline-block;
  -moz-transform: rotate(180deg);
  /* Для Firefox */
  -ms-transform: rotate(180deg);
  /* Для IE */
  -webkit-transform: rotate(180deg);
  /* Для Safari, Chrome, iOS */
  -o-transform: rotate(180deg);
  /* Для Opera */
  transform: rotate(180deg);
}

/* =================== color ===================*/
.green-color {
  color: #4caf50;
}

.red-color {
  color: #f44336;
}

.blue-color {
  color: #2196f3;
}

.yelow-color {
  color: #ffeb3b;
}

.orange-color {
  color: #ff9100;
}

.grey-dark-color {
  color: #616161;
}

.grey-light-color {
  color: #bdbdbd;
}

.grey-middle-color {
  color: #b4b4b4;
}

.grey-tele-color {
  color: #c9c9c9;
}

.icon-hover-color {
  color: #d41e46;
}

.icon.disabled,
.icon.disabled:hover,
.icon-disabled,
.icon-disabled:hover {
  color: #bdbdbd;
  cursor: default;
}

.icon-not-active:after {
  color: #b4b4b4;
}

/*=================================================*/
.list-header-title .icon {
  color: #616161;
}

.icon-monitoring:before {
  content: '\E604';
}

.icon-tracking:before {
  content: '\E605';
}

.change-monitoring-type {
  cursor: pointer;
}

.change-monitoring-type.all:before {
  content: '\E915';
}

.change-monitoring-type.add:before {
  content: '\E914';
}

.change-monitoring-type.unit:before {
  content: '\E912';
}

.change-monitoring-type.group:before {
  content: '\E913';
}

.add-to-list:before {
  content: '\E919';
}

.remove-add-to-list:before {
  content: '\E91A';
}

.units:before {
  content: '\E610';
}

.geozones:before {
  content: '\E609';
}

.search-ctrl-label {
  position: relative;
}

.search-ctrl-label input {
  padding-left: 22px;
}

.search-ctrl-label:after {
  font-family: HostingFont !important;
  content: '\E600';
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  top: 4px;
  left: 5px;
  position: absolute;
}

.icon-reports:before {
  content: '\E607';
}

.time-schedule .none:before {
  content: '\e924';
}

.time-schedule .even:before {
  content: '\e923';
}

.time-schedule .odd:before {
  content: '\e922';
}

.booklog {
  opacity: 0.5;
}

.booklog.active {
  opacity: 1;
}

.booklog:before {
  content: '\E908';
}
