report-form,
afaqy-report-schedule-form,
afaqy-report-schedule-view {
  width: 100%;
  .report-form {
    background-image: none;
    height: calc(var(--page-content-height) - 0px) !important;
    .page-content-title {
      padding: 18px 10px 14px;
    }
    report-summaryby {
      .summary-by {
        padding-left: 0px;
      }
      .form-control-label {
        color: #999999;
      }
    }
    .section {
      border-bottom: 0px !important;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .actionButtons {
      background: none;
      justify-content: flex-end !important;
      margin-right: 14px;
      padding-top: 10px;
      margin-left: 14px;
      padding-bottom: 10px;
      .btn-cancel {
        background-color: #e4f2f2;
        color: #0c6566;
        margin-right: 27px !important;
        border-radius: 4px;
        padding: 10px 20px;
      }
      .btn-save {
        padding: 10px 30px;
        border-radius: 4px;
        border: none;
      }
      .btn-outline-primary {
        color: green;
        background: red;
      }
      .btn-outline-primary:not(:disabled):not(.disabled) {
        color: #fff !important;
        background-color: var(--them-color);
      }
    }
    .form-control-label {
      color: #999999;
    }
    #reportWidthCtrl {
      width: 100% !important;
      margin: 15px;
      border-radius: 10px;
      height: calc(var(--page-content-height) - 53px);
      background-color: #fff;
      .flexgridSelectNonSelect {
        width: inherit;
      }
      // wj-flex-grid {
      //     padding: 10px;
      // }
      .flexgridSelectNonSelect wj-flex-grid {
        height: calc(var(--page-content-height) - 228px);
        background-color: #f7f7f7;
      }
    }
    .page-content-title {
      color: var(--them-color);
      background: none;
    }
    .white-background {
      background: none;
    }
    afaqy-date-calendar {
      .form-group-container {
        padding-left: 36%;
      }
      wj-input-number {
        .wj-btn {
          background-color: #e4f2f2 !important;
          border-right: none !important;
          border-left: none !important;
          border-radius: 4px;
          color: var(--them-color);
        }
        .wj-input-group-btn {
          padding: 2px;
        }
        .wj-form-control {
          text-align: center !important;
        }
      }

      .customPadding {
        border-radius: 27px !important;
        border: none !important;
        height: 35px;
      }
      .checkbox-squared {
        label {
          color: #414141;
        }
      }
    }
    #fields {
      padding: 0px;
      border: 0px;
    }
    .table-container {
      padding: 0px 10px 0px 18px;
    }
    .nav-tabs {
      border: 0px;
      margin-top: 20px;
      .nav-item {
        > .nav-link {
          position: relative;
          padding: 7px 12px 3px;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          font-weight: normal;
          margin-bottom: 9px;
          height: 35px;
          &.active,
          &:hover {
            color: white;
            border-radius: 16px;
            font-weight: normal;
            background-color: var(--them-color);
            height: 35px !important;
            &::before {
              content: '';
              display: block;
              position: none;
              top: 0;
              left: 0;
              height: 4px;
              background: none;
              width: 100%;
              border-radius: 4px 4px 0 0;
            }
          }
        }
      }
    }
    .report-resource-list {
      border: 0px;
      border-radius: 10px;
    }
    #report-resource-list.wj-flexgrid .wj-cell {
      padding: 10px 0px;
      line-height: 27px;
      display: flex;
      align-items: center;
    }
    .wj-cell.wj-frozen:not(.wj-header):not(.wj-group):not(
        .wj-state-selected
      ):not(.wj-state-multi-selected),
    .wj-cell.wj-frozen.wj-alt:not(.wj-header):not(.wj-group):not(
        .wj-state-selected
      ):not(.wj-state-multi-selected) {
      background: #f7f7f7;
      border: 0px;
      border-bottom: 1px solid #e8e8e8;
      color: #414141 !important;
    }
    .wj-state-selected,
    .wj-state-multi-selected {
      background-color: #f7f7f7 !important;
      color: #414141 !important;
      border: 0px;
      border-bottom: 1px solid #e8e8e8;
    }
    .wj-cells {
      background-color: #f7f7f7;
    }
  }
  .reportsOptions {
    height: calc(var(--page-content-height) - 175px) !important;
  }
  .afaqy-custom-select-container {
    padding-right: 46px;
  }
  color-ranges {
    padding-right: 30px;
  }
  .tab-content {
    > .active {
      border: none !important;
      padding: 10px !important;
      background-color: #f7f7f7 !important;
      border-radius: 10px !important;
    }
  }
  .flexGridWithSelect wj-flex-grid {
    height: calc(var(--page-content-height) - 263px) !important;
  }

  .styledCheckBox {
    /* Basic styling */

    [type='checkbox'] {
      height: 18px !important;
      width: 18px !important;
      color: var(--them-color);
      vertical-align: middle;
      -webkit-appearance: none;
      background: #969696;
      outline: 0;
      flex-grow: 0;
      border: 1px solid #969696;
      border-radius: 4px;
      background-color: #ffffff;
      cursor: pointer;
    }

    /* Pseudo element for check styling */

    [type='checkbox']::before {
      content: '';
      color: transparent;
      display: block;
      width: inherit;
      height: inherit;
      border-radius: inherit;
      border: 0;
      background-color: transparent;
      background-size: contain;
    }

    // /* Checked */

    [type='checkbox']:checked {
      background-color: currentcolor;
      display: flex;
      align-items: center;
    }

    [type='checkbox']:checked::before {
      box-shadow: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
    }

    // /* Disabled */

    [type='checkbox']:disabled {
      background-color: #ccd3d8;
      opacity: 0.84;
      cursor: not-allowed;
    }
  }
  time-limitation {
    .checkbox-2 {
      .checkboxFour {
        border: none;
        input {
          display: block;
          width: 16px;
          height: 16px;
          background: #ffffff;
          position: relative;
          border: 1px solid rgba(0, 0, 0, 0.2);
          margin: 0px;
        }
        input[type='checkbox']:checked + label {
          background: none;
        }
      }
      [type='checkbox'] {
        height: 18px !important;
        width: 18px !important;
        color: var(--them-color);
        vertical-align: middle;
        -webkit-appearance: none;
        background: #969696;
        outline: 0;
        flex-grow: 0;
        border: 1px solid #969696;
        border-radius: 4px;
        background-color: #ffffff;
        cursor: pointer;
      }

      /* Pseudo element for check styling */

      [type='checkbox']::before {
        content: '';
        color: transparent;
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        border: 0;
        background-color: transparent;
        background-size: contain;
      }

      // /* Checked */

      [type='checkbox']:checked {
        background-color: currentcolor;
        display: flex;
        align-items: center;
      }

      [type='checkbox']:checked::before {
        box-shadow: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
      }

      // /* Disabled */

      [type='checkbox']:disabled {
        background-color: #ccd3d8;
        opacity: 0.84;
        cursor: not-allowed;
      }
    }

    .timelimit-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .slider-container {
        width: 100%;
        padding-right: 10px;
      }
      .btn {
        border-radius: 21px;
        height: 23px;
        padding: 0px 10px;
        background: #e4f2f2 !important;
        border: 0px;
        color: #414141;
      }
    }
    .picker {
      justify-content: flex-start;
    }

    .picker .item {
      border: 1px solid var(--them-color);
    }
    .noUi-horizontal {
      height: 4px;
    }
    .picker-container {
      border-top: 1px dashed var(--them-color);
      padding-top: 10px;
      margin-top: 10px;
    }
    #slider-round {
      display: flex;
      justify-content: space-between;
      align-items: center;
      nouislider {
        width: 100%;
        padding: 0px 5px;
      }
    }
    #slider-round .noUi-connect {
      background: #c0392b;
    }

    #slider-round .noUi-handle {
      height: 18px;
      width: 18px;
      top: -8px;
      right: -9px; /* half the width */
      border-radius: 9px;
    }
    .noUi-horizontal .noUi-tooltip {
      color: white;
      border-radius: 6px;
      background: #202020;
      height: 24px;
      font-size: 11px;
      line-height: 23px;
      padding: 0px 8px;
    }
    .noUi-handle:after,
    .noUi-handle:before {
      display: none;
    }
    .week-days {
      justify-content: space-between;
      .title {
        width: 100%;
        p {
          margin: 0px 0px 5px;
        }
      }
      .item {
        background-color: #e4f2f2;
        color: var(--them-color);
        height: 35px;
        width: 59px;
        border: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .month {
      .title {
        width: 100%;
        p {
          margin: 0px 0px 5px;
        }
      }
      .item {
        background-color: #e4f2f2;
        color: var(--them-color);
        height: 35px;
        width: 65px;
        border: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    .days {
      .time-schedule {
        width: 100%;
      }
      .title {
        width: 100%;
        p {
          margin: 0px 0px 5px;
        }
      }

      .item {
        background-color: #e4f2f2;
        color: var(--them-color);
        height: 35px;
        width: 39px;
        border: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        margin-bottom: 6px;
      }
    }
    .update-bulk-days-container {
      .form-group {
        margin-bottom: 5px;
      }
      .customPadding {
        border-radius: 27px !important;
        border: none !important;
        height: 35px;
      }
    }
  }
  afaqy-custom-multiple-select {
    wj-flex-grid {
      max-height: calc(var(--page-content-height) - 259px) !important;
    }
  }
  report-resources {
    wj-flex-grid {
      max-height: calc(var(--page-content-height) - 252px) !important;
    }
  }
}
