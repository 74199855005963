*:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.wj-dropdown-panel .wj-state-selected,
.wj-state-last-selected {
  color: #fff !important;
  background: var(--them-color) !important;
}

/* Notification */
.simple-notification-wrapper {
  z-index: 1100 !important;
}

.wj-dropdown {
  padding-right: 4px !important;
}

.simple-notification {
  padding: 10px 18px !important;
  display: flex;
  align-items: center;
}
.ngxp__inner {
  padding: 0px 10px;
  max-height: 350px;
  overflow-y: auto;
}

.msg-container {
  background-color: #ffffff;
  border-radius: 6px !important;
  border: 1px solid darkred !important;
  box-shadow: 0 0 2px darkred !important;
  font-family: 'Roboto' !important;
  font-size: 14px;
  text-align: left;
  > div {
    > div:last-child {
      hr {
        display: none;
      }
    }
  }
  p {
    margin-bottom: 7px;
    strong {
      color: darkred;
    }
  }
  .ngxp__arrow {
    border-color: darkred !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    // display: none;
  }
}
wj-multi-select.form-control {
  padding: 0px !important;
}

.w-auto {
  width: auto !important;
}

unit-settings-copy-component,
afaqy-import-to-fill {
  .modal-dialog .modal-body {
    padding: 0px !important;
  }
  .modal-dialog .modal-footer {
    display: none !important;
  }
}

afaqy-report-schedule-form {
  afaqy-time-picker {
    .col-12 {
      .form-control-label {
        margin-bottom: 0px !important;
        line-height: 35px;
      }
      flex: 0 0 20%;
      max-width: 20%;
    }
    .col-12.input-group {
      flex: 0 0 50%;
      max-width: 50%;
      input {
        width: 254px;
        height: 37px;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
      }
    }
  }
}

second-by-second-list {
  .listOptions {
    z-index: 1050 !important; // fix dropdown hidden behind footer
    // adjust positioning to be next to button clicked
    margin-left: -16px !important;
    margin-top: -21px !important;
  }

  .options-list {
    margin-right: 30px;
  }
}
unit-form-custom {
  afaqy-custom-select {
    .ng-select .ng-select-container {
      border: 1px solid var(--them-color) !important;
      .ng-value-container .ng-placeholder {
        color: var(--them-color) !important;
      }
    }
  }

}
afaqy-share-location-modal {
  .share-location-component {
    tag-input .ng2-tag-input {
      min-height: 35px !important;
    }

    .ng-select-container {
      height: 35px !important;
    }

    .modal-body {
      padding: 20px !important;
      background: #fff !important;
      max-height: 660px;
    }

    .modal-footer {
      display: none;
    }
  }
}
