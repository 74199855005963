/* md up start */
@include media-breakpoint-up(xl) {
  .notificationsSlider {
    display: flex;
  }
}

/* md up end */
/* md down start */
@include media-breakpoint-down(xl) {
}

/* md down end */
