$activeColor: var(--them-color) !default;

// W&B Schema
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-250: #e2e2e2;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #cccccc;
$gray-600: #6c757d;
$gray-700: #191919;
$gray-800: #333333;
$gray-900: #222222;
$black: #000000;
$error-color: #dc3545;
$font-size-base: 0.9rem !default;
$spacer: 0.3rem !default;

:root {
  --them-color: #932a4f;
}

.wj-state-selected,
.wj-state-multi-selected {
  background: var(--them-color);
}

.wj-header {
  background: linear-gradient(
    to bottom,
    var(--them-color) 0%,
    #000 250%
  ) !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--them-color) !important;
}
.wj-group:not(.wj-state-selected):not(.wj-state-multi-selected) {
  background-color: $gray-200 !important;
}
.text-theme-color {
  color: var(--them-color) !important;
}
.btn-clear {
  background-color: transparent !important;
  border-color: transparent !important;
}
.dropdown-item.active {
  background: var(--them-color) !important;
}
.bg-primary {
  background: linear-gradient(
    to bottom,
    var(--them-color) 0%,
    #000 250%
  ) !important;
}

.border-primary {
  border-color: var(--them-color) !important;
}

.navbar-primary {
  background: linear-gradient(
    to bottom,
    var(--them-color) 0%,
    #000 250%
  ) !important;
}

.list-group-item-primary {
  color: var(--them-color) !important;
  background-color: $gray-200 !important;
}
.badge-primary {
  background-color: var(--them-color) !important;
}
.btn-primary {
  background: var(--them-color) !important;
  border-color: var(--them-color) !important;
  &:hover {
    background: var(--them-color) !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}
.btn-primary.focus {
  box-shadow: none !important;
}
.btn-primary.active {
  &:focus {
    box-shadow: none !important;
  }
  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2) !important;
  }
}
.btn-outline-primary {
  color: var(--them-color) !important;
  border-color: var(--them-color) !important;
  &:hover {
    color: white !important;
    background: var(--them-color) !important;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: var(--them-color) !important;
}

.p-relative {
  position: relative;
}
/* Bootstrap z-index Vars */

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-header: 1049;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
/* custom z-index */

$zindex-sidebar: 1048;
$zindex-scrollbar: 1046;
