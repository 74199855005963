/* md up start */
@include media-breakpoint-up(md) {
}

/* md up end */
/* md down start */
@include media-breakpoint-down(md) {
  .responsive {
    .chatBoxClass {
      .tab-content {
        margin-right: 15px !important;
      }

      wj-flex-grid {
        height: 94px !important;
      }

      .modal-dialog.modal-lg .modal-content .modal-body .tab-content > .active,
      .modal-dialog.modal-responsive
        .modal-content
        .modal-body
        .tab-content
        > .active {
        height: auto !important;
      }

      .modalbody {
        overflow-y: auto !important;
        max-height: 100% !important;
      }

      .content {
        height: auto !important;
      }

      .chat-container {
        height: auto !important;
      }

      .messagesContainer {
        height: 20vh;
        overflow-y: auto;
      }

      .intervals {
        padding: 10px 0;
      }
    }

    .assign-buttons {
      .mdi-chevron-double-right {
        transform: rotate(90deg);
      }
      .mdi-chevron-right {
        transform: rotate(90deg);
      }
      .mdi-chevron-double-left {
        transform: rotate(90deg);
      }
      .mdi-chevron-left {
        transform: rotate(90deg);
      }
    }

    ::ng-deep reports-form {
      .customH {
        height: auto;
      }
    }

    /* end responsive class */
  }

  .mainNavbar {
    justify-content: space-between !important;
  }

  .searchDivBox {
    display: inherit;
  }

  .msgSmall {
    display: none !important;
  }

  #nav {
    overflow-x: hidden;
  }

  .cssrtl .wsoffcanvasopener .animated-arrow {
    right: 230px;
  }
}

/* md down start */
